export default {
  title: 'Unyte Intake Form',
  logoPosition: 'right',
  pages: [
    {
      name: 'Intro',
      elements: [
        {
          type: 'html',
          name: 'question0',
          html:
            '<div><p class="custom-p">There are <b>28\n items</b> to complete.</p><p class="custom-p">The following questions are designed to help your provider understand your current circumstances and provide personalized insights for your listening journey.\n\n<p class="custom-p"> There are no right or wrong answers, and your initial responses are often the most meaningful. You can complete this process independently, with your provider, or with the support of someone you trust.</p></div>',
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          type: 'panel',
          name: 'test0',
          title: 'Health History',
          elements: [
            {
              type: 'comment',
              name: 'question1',
              title:
                'Briefly describe your mental and physical health history, including your current status, conditions, symptoms, recent procedures, or other challenges.',
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel1',
          title: 'Health History',
          elements: [
            {
              type: 'tagbox',
              name: 'question2',
              title: 'Do you experience any of the following related to your health?',
              choices: [
                {
                  value: 'Item 1',
                  text: 'Difficulty falling or staying asleep',
                },
                {
                  value: 'Item 2',
                  text: 'Constipation or loose stool',
                },
                {
                  value: 'Item 3',
                  text:
                    'Difficulty with swallowing, indigestion, or chest pain associated with eating',
                },
                {
                  value: 'Item 4',
                  text: 'Frequent headaches or migraines',
                },
                {
                  value: 'Item 5',
                  text: 'Chronic pain, chronic fatigue',
                },
                {
                  value: 'Item 6',
                  text: 'Low energy, difficulty taking care of yourself or others',
                },
                {
                  value: 'Item 7',
                  text: 'Muscle tension, shoulder/neck/back pain, body aches',
                },
                {
                  value: 'Item 8',
                  text: 'Frequent sweating or feeling hot in the face and palms',
                },
                {
                  value: 'Item 9',
                  text: 'Engaging in problematic substance use or related habits/behaviors',
                },
              ],
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel2',
          title: 'Health History',
          elements: [
            {
              type: 'rating',
              name: 'question3',
              title: 'How would you rate your overall health?',
              autoGenerate: false,
              rateValues: [
                {
                  value: 1,
                  text: 'Poor',
                },
                2,
                3,
                4,
                {
                  value: 5,
                  text: 'Well',
                },
              ],
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel3',
          title: 'Personal History',
          elements: [
            {
              type: 'comment',
              name: 'question4',
              title:
                'Describe your relevant personal history, including difficult childhood experiences, and current or expected stressors.',
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel4',
          title: 'Sensations',
          elements: [
            {
              type: 'comment',
              name: 'question5',
              title:
                'Describe how you respond to sensations in your environment (hearing, seeing, taste, smell, movement, etc.) and from within your body. Consider sensations that are pleasant or unpleasant, and how you respond to those sensations (e.g. avoidance of sound or touch, preference for stimulating environments, avoidance of strong smells, high-risk activities).',
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel5',
          title: 'Sensations',
          elements: [
            {
              type: 'tagbox',
              name: 'question6',
              title: 'Do you experience any of the following related to sounds?',
              choices: [
                {
                  value: 'Item 1',
                  text: 'Certain sounds are strongly disliked',
                },
                {
                  value: 'Item 2',
                  text: 'Specific sounds have to be avoided or feel unbearable',
                },
                {
                  value: 'Item 3',
                  text:
                    'Hearing is typical, but processing and understanding what is heard is compromised',
                },
                {
                  value: 'Item 4',
                  text: 'Difficulty hearing speech when there is background noise',
                },
                {
                  value: 'Item 5',
                  text: 'Feeling drawn to or enjoying loud or noisy environments',
                },
              ],
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel6',
          title: 'Sensations',
          elements: [
            {
              type: 'rating',
              name: 'question7',
              title: 'How significantly do sensations impact your life experience?',
              autoGenerate: false,
              rateValues: [
                {
                  value: 'Item 1',
                  text: 'No Significant',
                },
                {
                  value: 'Item 2',
                  text: '2',
                },
                {
                  value: 'Item 3',
                  text: '3',
                },
                {
                  value: 'Item 4',
                  text: '4',
                },
                {
                  value: 'Item 5',
                  text: 'Significant Impact',
                },
              ],
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel7',
          title: 'Nervous System Regulation',
          elements: [
            {
              type: 'comment',
              name: 'question8',
              title:
                'When reacting to distressing events in our lives, it is normal for us to quickly move out of a restful, connected state and into a defensive state (e.g. fight, flight or shutdown response). Describe how you typically respond to stress or challenges.',
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel9',
          title: 'Nervous System Regulation',
          elements: [
            {
              type: 'rating',
              name: 'question10',
              title: 'How affected do you feel by stress or life challenges?',
              autoGenerate: false,
              rateValues: [
                {
                  value: 1,
                  text: 'Not Affected',
                },
                2,
                3,
                4,
                {
                  value: 5,
                  text: 'Highly Affected',
                },
              ],
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel8',
          title: 'Support',
          elements: [
            {
              type: 'comment',
              name: 'question9',
              title:
                'Who do you consider to be a part of your support network and how often are you in contact with them? Consider your household, family, friends and community.',
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel10',
          title: 'Support',
          elements: [
            {
              type: 'rating',
              name: 'question11',
              title: 'What is the strength and stability of your support system?',
              autoGenerate: false,
              rateValues: [
                {
                  value: 1,
                  text: 'Weak or Unstable Support',
                },
                2,
                3,
                4,
                {
                  value: 5,
                  text: 'Strong and Stable Support',
                },
              ],
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel11',
          title: 'Resources',
          elements: [
            {
              type: 'comment',
              name: 'question12',
              title:
                'Describe your access to resources, including basic needs like shelter, nutrition, and physical safety, in addition to access to medical care, financial security, work and education.',
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel12',
          title: 'Resources',
          elements: [
            {
              type: 'rating',
              name: 'question13',
              title: 'What access do you have to resources?\n',
              autoGenerate: false,
              rateValues: [
                {
                  value: 1,
                  text: 'Limited Resources',
                },
                2,
                3,
                4,
                {
                  value: 5,
                  text: 'Abundant Resources',
                },
              ],
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel13',
          title: 'Preparing for Listening',
          elements: [
            {
              type: 'rating',
              name: 'question14',
              title: 'What is your general feeling at home?',
              autoGenerate: false,
              rateValues: [
                {
                  value: 1,
                  text: 'Calm',
                },
                2,
                3,
                4,
                {
                  value: 5,
                  text: 'Chaotic',
                },
              ],
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel14',
          title: 'Preparing for Listening',
          elements: [
            {
              type: 'rating',
              name: 'question15',
              title: 'What are the noise levels in your home?',
              autoGenerate: false,
              rateValues: [
                {
                  value: 1,
                  text: 'Peaceful, Quiet',
                },
                2,
                3,
                4,
                {
                  value: 5,
                  text: 'Frenzied, Loud',
                },
              ],
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel15',
          title: 'Preparing for Listening',
          elements: [
            {
              type: 'comment',
              name: 'question16',
              title:
                'Will you have access to a quiet space for listening, and will your privacy in these spaces be respected?',
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel16',
          title: 'Preparing for Listening',
          elements: [
            {
              type: 'comment',
              name: 'question17',
              title:
                'Will you have access to a supportive environment to practice regulation between and after listening?',
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel17',
          title: 'Preparing for Listening',
          elements: [
            {
              type: 'tagbox',
              name: 'question18',
              title: 'Do you have access to any of the following?',
              choices: [
                {
                  value: 'Item 4',
                  text:
                    'Nature, open spaces, plants you care for in your home or garden, even a nice view from your window',
                },
                {
                  value: 'Item 5',
                  text: 'Pets, birds in your outdoor area, relationship with other animals',
                },
                {
                  value: 'Item 2',
                  text: 'Movement practice (e.g. sports, exercise routine, dance, or walking)',
                },
                {
                  value: 'Item 3',
                  text: 'Yoga, meditation or spiritual practice',
                },
                {
                  value: 'Item 6',
                  text: 'Breathing exercises, singing in a choir, playing a wind instrument',
                },
                {
                  value: 'Item 7',
                  text: 'Other creative activities such as art, drama, or music',
                },
              ],
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel18',
          title: 'Prior Listening Experience',
          elements: [
            {
              type: 'comment',
              name: 'question19',
              title: 'Have you listened to the Unyte programs in the past?',
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel19',
          title: 'Prior Listening Experience',
          elements: [
            {
              type: 'comment',
              name: 'question20',
              title: 'Have you experienced other listening therapies?',
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel20',
          title: 'Prior Listening Experience',
          elements: [
            {
              type: 'rating',
              name: 'question21',
              title: 'How does music affect you generally?',
              autoGenerate: false,
              rateValues: [
                {
                  value: 1,
                  text: 'Calming, Grounding',
                },
                2,
                3,
                4,
                {
                  value: 5,
                  text: 'Aggravating, Irritating',
                },
              ],
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel21',
          title: 'Prior Listening Experience',
          elements: [
            {
              type: 'comment',
              name: 'question22',
              title: 'How often do you listen to music?',
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel22',
          title: 'Prior Listening Experience',
          elements: [
            {
              type: 'comment',
              name: 'question23',
              title: 'What type of music do you like?',
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel23',
          title: 'Prior Listening Experience',
          elements: [
            {
              type: 'comment',
              name: 'question24',
              title: 'How do the above vary depending on how you feel?',
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel24',
          title: 'Unforeseen Circumstances',
          elements: [
            {
              type: 'comment',
              name: 'question25',
              title:
                'Provide details about any events happening or expected in the next few weeks or months  that might influence your participation in listening therapies? (e.g., job stress, exams, medical procedures, house moves, bereavement, or other major life changes).',
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel25',
          title: 'Unforeseen Circumstances',
          elements: [
            {
              type: 'rating',
              name: 'question26',
              title: 'How does your life and world feel?',
              autoGenerate: false,
              rateValues: [
                {
                  value: 1,
                  text: 'Settled',
                },
                2,
                3,
                4,
                {
                  value: 5,
                  text: 'Unsettled',
                },
              ],
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel26',
          title: 'Part 8: Unforeseen Circumstances',
          elements: [
            {
              type: 'multipletext',
              name: 'question27',
              title:
                'What would you like to change as a result of participating in the Unyte program?',
              items: [
                {
                  name: 'text1',
                  title: '1.',
                },
                {
                  name: 'text2',
                  title: '2.',
                },
                {
                  name: 'text3',
                  title: '3.',
                },
              ],
            },
          ],
        },
        {
          type: 'panel',
          name: 'panel27',
          title: 'Unforeseen Circumstances',
          elements: [
            {
              type: 'comment',
              name: 'question28',
              title: 'What, if anything else, would you like your provider to know?',
            },
          ],
        },
      ],
    },
  ],
  showTitle: true,
  showProgressBar: 'bottom',
  goNextPageAutomatic: true,
  pageNextText: 'Skip',
  completeText: 'Skip',
  questionsOnPageMode: 'questionPerPage',
}
