export default {
  pages: [
    {
      name: 'Intro',
      elements: [
        {
          html:
            '<div><p class="custom-p">There are <b>10 items</b> to complete.</p><p class="custom-p">Our relationships and experiences even those in childhood can affect our health and well-being. In the following steps, indicate if you have had any of the experiences listed, as they may influence your health today or in the future.</p></div>',
          name: 'question0',
          type: 'html',
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          html:
            '<br /><p>Our relationships and experiences—even those in childhood—can affect our health and well-being. Difficult childhood experiences are very common. Please tell us whether you have had any of the experiences listed on the following pages, as they may be affecting your health today or may affect your health in the future. This information will help you and your provider better understand how to work together to support your health and well-being.</p>',
          type: 'html',
        },
      ],
    },
    {
      name: 'question1',
      type: 'rating',
      title:
        'Did you feel that you didn’t have enough to eat, had to wear dirty clothes, or had no one to protect or take care of you?',
      hideNumber: true,
      rateValues: [
        { text: 'Yes', value: 1 },
        { text: 'No', value: 0 },
      ],
    },
    {
      name: 'page4',
      elements: [
        {
          name: 'question2',
          type: 'rating',
          title: 'Did you lose a parent through divorce, abandonment, death, or other reason?',
          hideNumber: true,
          rateValues: [
            { text: 'Yes', value: 1 },
            { text: 'No', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'page4',
      elements: [
        {
          name: 'question3',
          type: 'rating',
          title: 'Did you live with anyone who was depressed, mentally ill, or attempted suicide?',
          hideNumber: true,
          rateValues: [
            { text: 'Yes', value: 1 },
            { text: 'No', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'page4',
      elements: [
        {
          name: 'question4',
          type: 'rating',
          title:
            'Did you live with anyone who had a problem with drinking or using drugs, including prescription drugs?',
          hideNumber: true,
          rateValues: [
            { text: 'Yes', value: 1 },
            { text: 'No', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'page4',
      elements: [
        {
          name: 'question5',
          type: 'rating',
          title:
            'Did your parents or adults in your home ever hit, punch, beat, or threaten to harm each other?',
          hideNumber: true,
          rateValues: [
            { text: 'Yes', value: 1 },
            { text: 'No', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'page4',
      elements: [
        {
          name: 'question6',
          type: 'rating',
          title: 'Did you live with anyone who went to jail or prison?',
          hideNumber: true,
          rateValues: [
            { text: 'Yes', value: 1 },
            { text: 'No', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'page4',
      elements: [
        {
          name: 'question7',
          type: 'rating',
          title:
            'Did a parent or adult in your home ever swear at you, insult you, or put you down?',
          hideNumber: true,
          rateValues: [
            { text: 'Yes', value: 1 },
            { text: 'No', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'page4',
      elements: [
        {
          name: 'question8',
          type: 'rating',
          title:
            'Did a parent or adult in your home ever hit, beat, kick, or physically hurt you in any way?',
          hideNumber: true,
          rateValues: [
            { text: 'Yes', value: 1 },
            { text: 'No', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'page4',
      elements: [
        {
          name: 'question9',
          type: 'rating',
          title: 'Did you feel that no one in your family loved you or thought you were special?',
          hideNumber: true,
          rateValues: [
            { text: 'Yes', value: 1 },
            { text: 'No', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'page4',
      elements: [
        {
          name: 'question10',
          type: 'rating',
          title:
            'Did you experience unwanted sexual contact (such as fondling or oral/anal/vaginal intercourse/penetration)?',
          hideNumber: true,
          rateValues: [
            { text: 'Yes', value: 1 },
            { text: 'No', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'page4',
      elements: [
        {
          name: 'question11',
          type: 'rating',
          title: 'Do you believe that these experiences have affected your health?',
          rateCount: 3,
          hideNumber: true,
          rateValues: [
            { text: 'Not Much', value: 10000 },
            { text: 'Some', value: 20000 },
            { text: 'A Lot', value: 30000 },
          ],
        },
      ],
    },
  ],
  title: 'ACE (Adverse Childhood Experience) Assessment',
  showTitle: false,
  completeText: 'Complete',
  pageNextText: 'Skip',
  goNextPageAutomatic: true,
  questionsOnPageMode: 'questionPerPage',
  firstPageIsStarted: true,
  startSurveyText: 'Start',
}
