export default {
  pages: [
    {
      name: 'Intro',
      elements: [
        {
          html:
            '<div><p class="custom-p">There are <b>50 items</b> to complete.</p><p class="custom-p">In the following questions, you will be asked about behaviors related to sensory processing profiles, including auditory, visual, tactile, and feeding behaviors, in your child. If you\'re unsure whether you\'ve ever displayed a behavior or have never displayed it, select \'Not sure/Not Applicable\'.</p></div>',
          name: 'question0',
          type: 'html',
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '1. How often does your child respond negatively to unexpected or loud noises (for example, hide or cringe at noise from ambulance, train, fire or car alarm, fireworks)?',
          elements: [
            {
              hideNumber: true,
              name: 'question1',
              title: ' ',
              type: 'rating',
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },

    {
      name: 'page2',
      elements: [
        {
          name: 'test',
          type: 'panel',
          title:
            '2. How often does your child become distracted, or have difficulty following verbal instructions when there is a lot of noise around?',
          elements: [
            {
              name: 'question2',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },

    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '3. How often does your child hold his/her hands over the ears?',
          elements: [
            {
              name: 'question3',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '4. How often does your child appear not to hear what you say (for example, does not seem to pay attention to what you say, appears to ignore you)?',
          elements: [
            {
              name: 'question4',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '5. How often does your child have trouble working with background noise (for example, air conditioner, traffic noises, airplanes)?',
          elements: [
            {
              name: 'question5',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '6. How often does your child not respond when his/her name is called, even though you know the child’s hearing is not a problem?',
          elements: [
            {
              name: 'question6',
              type: 'rating',

              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '7. How often is your child unusually angry, frightened, or in pain when others cry or scream?',
          elements: [
            {
              name: 'question7',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '8. How often do you have to speak loudly or get very close to your child’s face to get your child’s attention?',
          elements: [
            {
              name: 'question8',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '9. How often does your child seem unaware of continuous noise in the environment (for example, TV, stereo)?',
          elements: [
            {
              name: 'question9',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '10. How often does your child seem overly aware, distracted, or disturbed by continuous noise in the environment (for example, TV, stereo)?',
          elements: [
            {
              name: 'question10',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '11. How often does your child take a long time to respond when spoken to, even to familiar voices?',
          elements: [
            {
              name: 'question11',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '12. How often does your child startle easily at sound, compared to other children the same age, with loud or high- pitched noises (for example, vacuum, blender, fire alarms)?',
          elements: [
            {
              name: 'question12',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '13. How often is your child distracted by sounds not normally noticed by other people (for example, airconditioning fans, trains or planes outside)?',
          elements: [
            {
              name: 'question13',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '14. How often does your child respond negatively (i.e. tantrum, become distracted or anxious) when entering places with continuous background noises (for example, grocery stores, schools, shopping malls)?',
          elements: [
            {
              name: 'question14',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '15. How often is your child bothered by bright lights after your eyes or other children’s eyes have adapted to the same light?',
          elements: [
            {
              name: 'question15',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '16. How often does your child cover his/her eyes or squint?',
          elements: [
            {
              name: 'question16',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '17. How often does your child seem unable to tolerate bright lights?',
          elements: [
            {
              name: 'question17',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '18. How often does your child seem unable to tolerate flashing lights?',
          elements: [
            {
              name: 'question18',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '19. How often does your child get fussy when exposed to bright lights?',
          elements: [
            {
              name: 'question19',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '20. How often does your child seem sensitive to bright lights (for example, cries or closes eyes)?',
          elements: [
            {
              name: 'question20',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '21. How often does your child seem sensitive to flashing lights (for example, cries or closes eyes)?',
          elements: [
            {
              name: 'question21',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '22. How often does your child hesitate to go outside when it’s sunny?',
          elements: [
            {
              name: 'question22',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '23. How often does your child seem easily distracted by movement he/she can see?',
          elements: [
            {
              name: 'question23',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '24. How often does your child seem easily distracted by movements of objects (i.e. mechanical toys or cars)?',
          elements: [
            {
              name: 'question24',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '25. How often does your child seem distressed by tooth-brushing?',
          elements: [
            {
              name: 'question25',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '26. How often does your child seem distressed by face-washing?',
          elements: [
            {
              name: 'question26',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '27. How often does your child seem distressed by fingernail-cutting?',
          elements: [
            {
              name: 'question27',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '28. How often does your child seem distressed by hair-brushing?',
          elements: [
            {
              name: 'question28',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '29. How often does your child insist that labels or tags be removed from most clothing?',
          elements: [
            {
              name: 'question29',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '30. How often does your child refuse to wear certain fabrics or cry or fuss in response to wearing certain fabrics?',
          elements: [
            {
              name: 'question30',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '31. How often does your child complain that certain garments are too tight or scratchy?',
          elements: [
            {
              name: 'question31',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '32. How often does your child prefer to not wear clothing?',
          elements: [
            {
              name: 'question32',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '33. How often does your child resist hugging?',
          elements: [
            {
              name: 'question33',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '34. How often does your child react negatively or aggressively to hand-holding?',
          elements: [
            {
              name: 'question34',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '35. How often does your child react emotionally or aggressively to being touched?',
          elements: [
            {
              name: 'question35',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '36. How often does your child react emotionally or aggressively when touching very cold objects with his/her hands?',
          elements: [
            {
              name: 'question36',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '37. How often does your child react emotionally or aggressively when very cold objects touch his/her face?',
          elements: [
            {
              name: 'question37',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '38. How often does your child avoid certain tastes?',
          elements: [
            {
              name: 'question38',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '39. How often does your child resist certain textures of food?',
          elements: [
            {
              name: 'question39',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '40. How often does your child avoid certain food smells?',
          elements: [
            {
              name: 'question40',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '41. How often does your child resist certain temperatures of food?',
          elements: [
            {
              name: 'question41',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '42. How often does your child gag?',
          elements: [
            {
              name: 'question42',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '43. How often does your child vomit?',
          elements: [
            {
              name: 'question43',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '44. How often does your child have acid reflux?',
          elements: [
            {
              name: 'question44',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '45. How often does your child have excessive intestinal gas?',
          elements: [
            {
              name: 'question45',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '46. How often does your child become constipated?',
          elements: [
            {
              name: 'question46',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '47. How often does your child experience stomach or intestinal cramping?',
          elements: [
            {
              name: 'question47',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '48. How often does your child seem to have difficulty swallowing solid foods?',
          elements: [
            {
              name: 'question48',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '49. How often does your child suck on objects other than food (for example, pacifier, own tongue, thumb)?',
          elements: [
            {
              name: 'question49',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '50. How often does your child eat (or want to eat) significantly less than you think is appropriate for his/her size or age?',
          elements: [
            {
              name: 'question50',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
  ],
  title: 'Brain Body Center Sensory Scales (BBCSS)',
  showTitle: true,
  completeText: 'Complete',
  pageNextText: 'Skip',
  goNextPageAutomatic: true,
  questionsOnPageMode: 'questionPerPage',
  allowCompleteSurveyAutomatic: false,
  showCompletedPage: false,
  firstPageIsStarted: true,
  startSurveyText: 'Start',
}
