export default {
  pages: [
    {
      name: 'Intro',
      elements: [
        {
          html:
            '<div><p class="custom-p">There are <b>50 items</b> to complete.</p><p class="custom-p">In the following questions, you will be asked about behaviors related to sensory processing profiles, including auditory, visual, tactile, and feeding behaviors. If you\'re unsure whether you\'ve ever displayed a behavior or have never displayed it, select \'Not sure/Not Applicable\'.</p></div>',
          name: 'question0',
          type: 'html',
        },
      ],
    },
    {
      name: 'Auditory Processing (i)',
      title: 'Auditory Processing (i)',
      elements: [
        {
          name: 'question1',
          type: 'rating',
          title:
            'How often do you respond negatively to unexpected or loud noises (for example, hide or cringe at noise from ambulance, train, fire or car alarm, fireworks)?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question2',
          type: 'rating',
          title:
            'How often do you become distracted, or have difficulty following verbal instructions when there is a lot of noise around?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question3',
          type: 'rating',
          title: 'How often do you hold your hands over or plug your ears?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question4',
          type: 'rating',
          title:
            'How often do you not hear what others say (for example, you fail to pay attention to what others say)?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question5',
          type: 'rating',
          title:
            'How often do you have trouble working with background noise (for example, air conditioner, traffic noises, airplanes)?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'Auditory Processing (ii)',
      title: 'Auditory Processing (ii)',
      elements: [
        {
          name: 'question6',
          type: 'rating',
          title:
            'How often do you not respond when your name is called, even though you know your hearing is not a problem?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question7',
          type: 'rating',
          title:
            'How often are you unusually angry, frightened, or in pain when others cry or scream?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question8',
          type: 'rating',
          title:
            'How often do others have to speak loudly or get very close to your face to get your attention?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question9',
          type: 'rating',
          title:
            'How often are you unaware of continuous noise in the environment (for example, TV, stereo)?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question10',
          type: 'rating',
          title:
            'How often are you overly aware, distracted, or disturbed by continuous noise in the environment (for example, TV, stereo)?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'Auditory Processing (iii)',
      title: 'Auditory Processing (iii)',
      elements: [
        {
          name: 'question11',
          type: 'rating',
          title:
            'How often do you take a long time to respond when spoken to, even to familiar voices?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question12',
          type: 'rating',
          title:
            'How often do you startle easily at sound, compared to others, with loud or high-pitched noises (for example, vacuum, blender, fire alarms)?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question13',
          type: 'rating',
          title:
            'How often are you distracted by sounds not normally noticed by other people (for example, air conditioning fans, trains or planes outside)?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question14',
          type: 'rating',
          title:
            'How often do you respond negatively (i.e. become distracted or anxious) when entering places with continuous background noises (for example, grocery stores, schools, shopping malls)?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'Visual Processing (i)',
      title: 'Visual Processing (i)',
      elements: [
        {
          name: 'question15',
          type: 'rating',
          title:
            'How often are you bothered by bright lights after others’ eyes have adapted to the same light?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question16',
          type: 'rating',
          title: 'How often do you cover your eyes or squint?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question17',
          type: 'rating',
          title: 'How often are you unable to tolerate bright lights?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question18',
          type: 'rating',
          title: 'How often are you unable to tolerate flashing lights?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question19',
          type: 'rating',
          title: 'How often do you get agitated when exposed to bright lights?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'Visual Processing (ii)',
      title: 'Visual Processing (ii)',
      elements: [
        {
          name: 'question20',
          type: 'rating',
          title:
            'How often are you sensitive to bright lights (for example, squint or close eyes)?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question21',
          type: 'rating',
          title:
            'How often are you sensitive to flashing lights (for example, squint or close eyes)?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question22',
          type: 'rating',
          title: 'How often do you hesitate to go outside when it’s sunny?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question23',
          type: 'rating',
          title: 'How often are you easily distracted by movement only you can see?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question24',
          type: 'rating',
          title:
            'How often are you easily distracted by movements of objects (i.e. mechanical objects or repetitive movements)?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'Tactile Processing (Touch) (i)',
      title: 'Tactile Processing (Touch) (i)',
      elements: [
        {
          name: 'question25',
          type: 'rating',
          title: 'How often are you distressed or overly-sensitive to tooth-brushing?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question26',
          type: 'rating',
          title: 'How often are you distressed or overly-sensitive to face-washing?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question27',
          type: 'rating',
          title: 'How often are you distressed or overly-sensitive to fingernail-cutting?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question28',
          type: 'rating',
          title: 'How often are you distressed or overly-sensitive to hair-brushing?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question29',
          type: 'rating',
          title:
            'How often do you remove labels or tags from most clothing, or ask that they be removed?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'Tactile Processing (Touch) (ii)',
      title: 'Tactile Processing (Touch) (ii)',
      elements: [
        {
          name: 'question30',
          type: 'rating',
          title:
            'How often do you refuse to wear certain fabrics or find certain fabrics irritating?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question31',
          type: 'rating',
          title: 'How often do you find certain garments are too tight, scratchy or irritating?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question32',
          type: 'rating',
          title: 'How often do you prefer to not wear certain clothing items?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question33',
          type: 'rating',
          title: 'How often do you resist hugging?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question34',
          type: 'rating',
          title: 'How often do you react negatively or overly sensitively to hand-holding?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'Tactile Processing (Touch) (iii)',
      title: 'Tactile Processing (Touch) (iii)',
      elements: [
        {
          name: 'question35',
          type: 'rating',
          title: 'How often do you react emotionally or overly sensitively to being touched?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question36',
          type: 'rating',
          title:
            'How often do you react emotionally or overly sensitively when touching very cold objects with your hands?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question37',
          type: 'rating',
          title:
            'How often do you react emotionally or overly sensitively when very cold objects touch your face?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'Ingestion and Digestion (i)',
      title: 'Ingestion and Digestion (i)',
      elements: [
        {
          name: 'question38',
          type: 'rating',
          title: 'How often do you avoid certain tastes?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question39',
          type: 'rating',
          title: 'How often do you resist certain textures of food?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question40',
          type: 'rating',
          title: 'How often do you avoid certain food smells?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question41',
          type: 'rating',
          title: 'How often do you resist certain temperatures of food?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question42',
          type: 'rating',
          title: 'How often do you gag?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'Ingestion and Digestion (ii)',
      title: 'Ingestion and Digestion (ii)',
      elements: [
        {
          name: 'question43',
          type: 'rating',
          title: 'How often do you vomit?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question44',
          type: 'rating',
          title: 'How often do you have acid reflux?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question45',
          type: 'rating',
          title: 'How often do you have excessive intestinal gas?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question46',
          type: 'rating',
          title: 'How often are you constipated?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question47',
          type: 'rating',
          title: 'How often do you experience stomach or intestinal cramping?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'Ingestion and Digestion (iii)',
      title: 'Ingestion and Digestion (iii)',
      elements: [
        {
          name: 'question48',
          type: 'rating',
          title: 'How often do you have difficulty swallowing solid foods?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question49',
          type: 'rating',
          title:
            'How often do you suck on objects other than food (for example, pen, lip, own tongue)?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question50',
          type: 'rating',
          title:
            'How often do you eat (or want to eat) significantly less than you think is appropriate for your size or age?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
      ],
    },
  ],
  title: 'BBC Questionnaire (Adult)',
  showTitle: false,
  completeText: 'Skip',
  pageNextText: 'Skip',
  showProgressBar: 'bottom',
  goNextPageAutomatic: true,
  questionsOnPageMode: 'questionPerPage',
  firstPageIsStarted: true,
  startSurveyText: 'Start',
}
