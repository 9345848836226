export default {
  pages: [
    {
      name: 'Intro',
      elements: [
        {
          html:
            '<div><p class="custom-p">There are <b>35 items</b> to complete.</p><p class="custom-p">Answer the following questions by selecting the option that best fits you.</p></div>',
          name: 'question0',
          type: 'html',
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '1. Please mark under the heading that best fits you:',
          elements: [
            {
              hideNumber: true,
              name: 'question1',
              title: 'Complain of aches or pains',
              type: 'rating',
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },

    {
      name: 'page2',
      elements: [
        {
          name: 'test',
          type: 'panel',
          title: '2. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question2',
              type: 'rating',
              title: 'Spend more time alone',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
              colCount: 4, // This sets the buttons to be inline if space allows
            },
          ],
        },
      ],
    },

    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '3. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question3',
              type: 'rating',
              title: 'Tire easily, little energy',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '4. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question4',
              type: 'rating',
              title: 'Fidgety, unable to sit still',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '5. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question5',
              type: 'rating',
              title: 'Have trouble with teacher',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '6. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question6',
              type: 'rating',

              title: 'Less interested in school',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '7. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question7',
              type: 'rating',
              title: 'Act as if driven by motor',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '8. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question8',
              type: 'rating',
              title: 'Daydream too much',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '9. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question9',
              type: 'rating',
              title: 'Distract easily',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '10. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question10',
              type: 'rating',
              title: 'Are afraid of new situations',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '11. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question11',
              type: 'rating',
              title: 'Feel sad, unhappy',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '12. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question12',
              type: 'rating',
              title: 'Are irritable, angry',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '13. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question13',
              type: 'rating',
              title: 'Feel hopeless',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '14. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question14',
              type: 'rating',
              title: 'Have trouble concentrating',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '15. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question15',
              type: 'rating',
              title: 'Less interested in friends',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '16. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question16',
              type: 'rating',
              title: 'Fight with other children',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '17. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question17',
              type: 'rating',
              title: 'Less interested in friends',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '18. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question18',
              type: 'rating',
              title: 'I wanted to help others relax',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: 'Down on yourself',
          elements: [
            {
              name: 'question19',
              type: 'rating',
              title: 'Down on yourself',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '20. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question20',
              type: 'rating',
              title: 'Visits doctor with doctor finding nothing wrong',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '21. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question21',
              type: 'rating',
              title: 'Have trouble sleeping',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '22. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question22',
              type: 'rating',
              title: 'Worry a lot',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '23. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question23',
              type: 'rating',
              title: 'Want to be with parent more than before',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '24. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question24',
              type: 'rating',
              title: 'Feel that you are bad',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '25. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question25',
              type: 'rating',
              title: 'Take unnecessary risks',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '26. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question26',
              type: 'rating',
              title: 'Get hurt frequently',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '27. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question27',
              type: 'rating',
              title: 'Seem to be having less fun',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '28. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question28',
              type: 'rating',
              title: 'Act younger than children your age',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '29. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question29',
              type: 'rating',
              title: 'Do not listen to rules',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '30. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question30',
              type: 'rating',
              title: 'Do not show feelings',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '31. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question31',
              type: 'rating',
              title: 'Do not understand other people’s feelings',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '32. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question32',
              type: 'rating',
              title: 'Tease others',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '33. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question33',
              type: 'rating',
              title: 'Blame others for your troubles',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '34. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question34',
              type: 'rating',
              title: 'Take things that do not belong to you',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '35. Please mark under the heading that best fits you:',
          elements: [
            {
              name: 'question35',
              type: 'rating',
              title: 'Refuse to share',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Never',
                  value: 0,
                },
                {
                  text: 'Sometimes',
                  value: 1,
                },
                {
                  text: 'Often',
                  value: 2,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  title: 'Pediatric Symptom Checklist - Youth Report (Y-PSC)',
  showProgressBar: 'bottom',
  startSurveyText: 'Start',
  pageNextText: 'Skip',
  goNextPageAutomatic: true,
  questionsOnPageMode: 'questionPerPage',
  firstPageIsStarted: true,
}
