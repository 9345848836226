import React, { useState } from 'react'
import { Box, Link, Stack, Typography } from '@mui/material'
import { useNavigate, useOutletContext } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
// new purchase service
import { useMutation, gql } from '@apollo/client'
import { client, AUTH_CREATE_PURCHASE } from 'utils/apollo/purchaseService/client'

import { extendData } from '../../../store/modules/new-purchase'
import useCart from '../../../views/new-purchase/utils/useCart'
import { PRODUCTS } from 'utils/constants/prices'
import useGTM from 'utils/hooks/useGTM'
import Button from './PurchaseCardButton'
import Card from './PurchaseCardComponent'
import CardHeader from './PurchaseCardHeader'
import {
  RRP_BANNER_STATE_2_ACCEPTED,
  RRP_BANNER_STATE_3_ACTIVE,
} from '../../../utils/constants/rrp-banner-cards'

const MARGIN_LEFT = '0.5rem'

const FREE_SAMPLE_DEFER_SKU_PURCHASE = gql`
  mutation RrpSkuToPurchaseAfterSampleMutation($rrpSku: String!) {
    addRrpSkuToPurchaseAfterSample(rrpSku: $rrpSku)
  }
`

export default function PurchaseCard({
  title,
  oneTimeCost,
  costPerMonth,
  description,
  trainingMessage,
  subDescription,
  hasMultiProduct,
  link,
  multiProductDiscountAmt,
  rrpFreeSampleState,
  skus,
  marginLeft = MARGIN_LEFT,
  testName,
}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { addToCart } = useGTM()
  const { loading, setLoading } = useOutletContext()
  const { uuid: userUuid, professionalCredentials, roles } = useSelector((state) => state.auth.user)

  const { uuid: purchaseUuid } = useSelector((state) => state.newPurchase.data)
  const {
    hasOrgSspCertificationCompleted,
    hasOrgRrpCertificationCompleted,
    hasOrgFocusCertificationCompleted,
    hasOrgSspCertificationEnrolled,
    hasOrgRrpCertificationEnrolled,
    hasOrgFocusCertificationEnrolled,
  } = useSelector((state) => state.organization)
  const focusTrainingStatus =
    (hasOrgFocusCertificationCompleted && 'completed') ||
    (hasOrgFocusCertificationEnrolled && 'enrolled')
  const sspTrainingStatus =
    (hasOrgSspCertificationCompleted && 'completed') ||
    (hasOrgSspCertificationEnrolled && 'enrolled')
  const rrpTrainingStatus =
    (hasOrgRrpCertificationCompleted && 'completed') ||
    (hasOrgRrpCertificationEnrolled && 'enrolled')

  const { calculateChargesAndPromoDiscounts } = useCart()
  const isInternalPurchase = true

  const mapSkuToPromoDiscounts = (purchasedSkus = []) => {
    const discountItems = calculateChargesAndPromoDiscounts(purchasedSkus, isInternalPurchase)
      .bundleDiscountItems

    const skuToPromoDiscounts = {}
    for (const discountItem of discountItems) {
      skuToPromoDiscounts[discountItem.applyToSku] = {
        ...discountItem,
      }
    }

    return skuToPromoDiscounts
  }
  const [createPurchase] = useMutation(AUTH_CREATE_PURCHASE, {
    client,
    variables: { params: { data: {} } },
  })

  const [addRrpSkuToPurchaseAfterSample] = useMutation(FREE_SAMPLE_DEFER_SKU_PURCHASE)
  const [showDeferredPurchaseResult, setShowDeferredPurchaseResult] = useState(false)

  const handlePurchase = ({ skus }) => async () => {
    if ([RRP_BANNER_STATE_2_ACCEPTED, RRP_BANNER_STATE_3_ACTIVE].includes(rrpFreeSampleState)) {
      try {
        await addRrpSkuToPurchaseAfterSample({
          variables: {
            rrpSku: skus[0],
          },
        })
        await navigate('/store/rrpFreeTrial')
      } catch (err) {
        console.log(err)
        setShowDeferredPurchaseResult(true)
      }
      return
    }

    // this should be in a separate function..
    try {
      setLoading(true)

      const skuToPromoDiscounts = mapSkuToPromoDiscounts(skus)

      const items = skus.map((sku) => ({
        item_id: PRODUCTS[sku].sku,
        item_name: PRODUCTS[sku].cartTitle,
        price: PRODUCTS[sku].price,
        item_category: PRODUCTS[sku].gtmCategory,
        item_brand: 'Unyte',
        quantity: 1,
        item_variant: PRODUCTS[sku].gtmVariant,
        immediateChargePrice: PRODUCTS[sku].immediateChargePrice,
        discount: skuToPromoDiscounts[sku]?.discountAmount || 0,
      }))
      const value = items.reduce((a, b) => a + (b.immediateChargePrice - b.discount), 0)

      const getNewUuid = async () => {
        const internalPurchaseData = await createPurchase()
        return get(internalPurchaseData, 'data.auth_create_purchase.uuid', null)
      }

      await dispatch(
        extendData({
          uuid: purchaseUuid || (await getNewUuid()),
          selectedAddOns: {},
          clientLicenses: {},
          onboardingProducts: [...skus],
          isInternalPurchase,
        })
      )

      await addToCart({
        value,
        items,
        uuid: userUuid,
        professionalCredentials,
        roles,
        focusTrainingStatus,
        sspTrainingStatus,
        rrpTrainingStatus,
      })
      await navigate(link)
    } finally {
      setLoading(false)
    }
  }

  function getCardHeaderTitle({
    oneTimeCost,
    costPerMonth,
    multiProductDiscountAmt,
    hasMultiProduct,
  }) {
    if (oneTimeCost !== undefined) return oneTimeCost === 0 ? 'Free' : `${oneTimeCost}/month`
    if (!hasMultiProduct) return `${costPerMonth}/month`
    const strikethroughCost = `${costPerMonth}`
    return (
      <>
        {multiProductDiscountAmt && (
          <>
            <span style={{ color: 'gray', fontSize: '1.35rem' }}>
              <s>${strikethroughCost}/month</s>
            </span>
            <br />
          </>
        )}
        {`$${costPerMonth - (multiProductDiscountAmt || 0)}/month`}
      </>
    )
  }

  // todo what does navigate to 0 do?
  const handleTryAgain = () => {
    navigate(0)
  }

  return (
    <Card backgroundColor="#eef7f2" data-test={testName}>
      <Stack direction="column" sx={{ height: '100%' }}>
        <CardHeader sx={{ marginLeft: MARGIN_LEFT }} title={title} color="#3c7e7d" />
        <CardHeader
          title={getCardHeaderTitle({
            costPerMonth,
            oneTimeCost,
            hasMultiProduct,
            multiProductDiscountAmt,
          })}
          color="#ce0093"
          subheader={description}
          sx={{ padding: '16px 16px 0 16px', marginLeft: MARGIN_LEFT }}
        />
        {trainingMessage && (
          <CardHeader
            sx={{
              marginLeft,
              minHeight: '20px',
            }}
            subheader={trainingMessage}
          />
        )}
        {subDescription && (
          <CardHeader
            sx={{
              marginLeft,
              minHeight: '100px', // to "fill out" 3-month plan (no subDescription)
            }}
            subheader={subDescription}
          />
        )}

        <Box sx={{ marginTop: 'auto', textAlign: 'center' }}>
          {showDeferredPurchaseResult && (
            <Typography variant="body2">
              We were not able to complete your purchase please{' '}
              <Link onClick={handleTryAgain} sx={{ cursor: 'pointer' }}>
                try again
              </Link>{' '}
              or contact customer support
            </Typography>
          )}
          {!showDeferredPurchaseResult && (
            <Button onClick={handlePurchase({ skus })} disabled={loading}>
              Purchase
            </Button>
          )}
        </Box>
      </Stack>
    </Card>
  )
}
