export default {
  pages: [
    {
      name: 'Intro',
      elements: [
        {
          html:
            '<div><p class="custom-p">There are <b>50 items</b> to complete.</p><p class="custom-p">In the following questions, you will be asked about behaviors related to sensory processing profiles, including auditory, visual, tactile, and feeding behaviors. If you\'re unsure whether you\'ve ever displayed a behavior or have never displayed it, select \'Not sure/Not Applicable\'.</p></div>',
          name: 'question0',
          type: 'html',
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '1. How often do you respond negatively to unexpected or loud noises (for example, hide or cringe at noise from ambulance, train, fire or car alarm, fireworks)?',
          elements: [
            {
              hideNumber: true,
              name: 'question1',
              title: ' ',
              type: 'rating',
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },

    {
      name: 'page2',
      elements: [
        {
          name: 'test',
          type: 'panel',
          title:
            '2. How often do you become distracted, or have difficulty following verbal instructions when there is a lot of noise around?',
          elements: [
            {
              name: 'question2',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },

    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '3. How often do you hold your hands over or plug your ears?',
          elements: [
            {
              name: 'question3',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '4. How often do you not hear what others say (for example, you fail to pay attention to what others say)?',
          elements: [
            {
              name: 'question4',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '5. How often do you have trouble working with background noise (for example, air conditioner, traffic noises, airplanes)?',
          elements: [
            {
              name: 'question5',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '6. How often do you not respond when your name is called, even though you know your hearing is not a problem?',
          elements: [
            {
              name: 'question6',
              type: 'rating',

              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '7. How often are you unusually angry, frightened, or in pain when others cry or scream?',
          elements: [
            {
              name: 'question7',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '8. How often do others have to speak loudly or get very close to your face to get your attention?',
          elements: [
            {
              name: 'question8',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '9. How often are you unaware of continuous noise in the environment (for example, TV, stereo)?',
          elements: [
            {
              name: 'question9',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '10. How often are you overly aware, distracted, or disturbed by continuous noise in the environment (for example, TV, stereo)?',
          elements: [
            {
              name: 'question10',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '11. How often do you take a long time to respond when spoken to, even to familiar voices?',
          elements: [
            {
              name: 'question11',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '12. How often do you startle easily at sound, compared to others, with loud or high-pitched noises (for example, vacuum, blender, fire alarms)?',
          elements: [
            {
              name: 'question12',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '13. How often are you distracted by sounds not normally noticed by other people (for example, air conditioning fans, trains or planes outside)?',
          elements: [
            {
              name: 'question13',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '14. How often do you respond negatively (i.e. become distracted or anxious) when entering places with continuous background noises (for example, grocery stores, schools, shopping malls)?',
          elements: [
            {
              name: 'question14',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '15. How often are you bothered by bright lights after others’ eyes have adapted to the same light?',
          elements: [
            {
              name: 'question15',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '16. How often do you cover your eyes or squint?',
          elements: [
            {
              name: 'question16',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '17. How often are you unable to tolerate bright lights?',
          elements: [
            {
              name: 'question17',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '18. How often are you unable to tolerate flashing lights?',
          elements: [
            {
              name: 'question18',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '19. How often do you get agitated when exposed to bright lights?',
          elements: [
            {
              name: 'question19',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '20. How often are you sensitive to bright lights (for example, squint or close eyes)?',
          elements: [
            {
              name: 'question20',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '21. How often are you sensitive to flashing lights (for example, squint or close eyes)?',
          elements: [
            {
              name: 'question21',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '22. How often do you hesitate to go outside when it’s sunny?',
          elements: [
            {
              name: 'question22',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '23. How often are you easily distracted by movement only you can see?',
          elements: [
            {
              name: 'question23',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '24. How often are you easily distracted by movements of objects (i.e. mechanical objects or repetitive movements)?',
          elements: [
            {
              name: 'question24',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '25. How often are you distressed or overly-sensitive to tooth-brushing?',
          elements: [
            {
              name: 'question25',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '26. How often are you distressed or overly-sensitive to face-washing?',
          elements: [
            {
              name: 'question26',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '27. How often are you distressed or overly-sensitive to fingernail-cutting?',
          elements: [
            {
              name: 'question27',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '28. How often are you distressed or overly-sensitive to hair-brushing?',
          elements: [
            {
              name: 'question28',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '29. How often do you remove labels or tags from most clothing, or ask that they be removed?',
          elements: [
            {
              name: 'question29',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '30. How often do you refuse to wear certain fabrics or find certain fabrics irritating?',
          elements: [
            {
              name: 'question30',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '31. How often do you find certain garments are too tight, scratchy or irritating?',
          elements: [
            {
              name: 'question31',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '32. How often do you prefer to not wear certain clothing items?',
          elements: [
            {
              name: 'question32',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '33. How often do you resist hugging?',
          elements: [
            {
              name: 'question33',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '34. How often do you react negatively or overly sensitively to hand-holding?',
          elements: [
            {
              name: 'question34',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '35. How often do you react emotionally or overly sensitively to being touched?',
          elements: [
            {
              name: 'question35',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '36. How often do you react emotionally or overly sensitively when touching very cold objects with your hands?',
          elements: [
            {
              name: 'question36',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '37. How often do you react emotionally or overly sensitively when very cold objects touch your face?',
          elements: [
            {
              name: 'question37',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '38. How often do you avoid certain tastes?',
          elements: [
            {
              name: 'question38',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '39. How often do you resist certain textures of food?',
          elements: [
            {
              name: 'question39',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '40. How often do you avoid certain food smells?',
          elements: [
            {
              name: 'question40',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '41. How often do you resist certain temperatures of food?',
          elements: [
            {
              name: 'question41',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '42. How often do you gag?',
          elements: [
            {
              name: 'question42',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '43. How often do you vomit?',
          elements: [
            {
              name: 'question43',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '44. How often do you have acid reflux?',
          elements: [
            {
              name: 'question44',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '45. How often do you have excessive intestinal gas?',
          elements: [
            {
              name: 'question45',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '46. How often are you constipated?',
          elements: [
            {
              name: 'question46',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '47. How often do you experience stomach or intestinal cramping?',
          elements: [
            {
              name: 'question47',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '48. How often do you have difficulty swallowing solid foods?',
          elements: [
            {
              name: 'question48',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '49. How often do you suck on objects other than food (for example, pen, lip, own tongue)?',
          elements: [
            {
              name: 'question49',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '50. How often do you eat (or want to eat) significantly less than you think is appropriate for your size or age?',
          elements: [
            {
              name: 'question50',
              type: 'rating',
              title: ' ',
              hideNumber: true,
              rateValues: [
                { text: 'Almost Always', value: 4 },
                { text: 'Frequently/ Often', value: 3 },
                { text: 'Sometimes/ Occasionally', value: 2 },
                { text: 'Almost Never ', value: 1 },
                { text: 'Not Sure/ Not Applicable', value: 0 },
              ],
            },
          ],
        },
      ],
    },
  ],
  title: 'Brain Body Center Sensory Scales (BBCSS)',
  showTitle: true,
  completeText: 'Complete',
  pageNextText: 'Skip',
  goNextPageAutomatic: true,
  questionsOnPageMode: 'questionPerPage',
  allowCompleteSurveyAutomatic: false,
  showCompletedPage: false,
  firstPageIsStarted: true,
  startSurveyText: 'Start',
}
