export default {
  pages: [
    {
      name: 'Intro',
      elements: [
        {
          html:
            '<div><p class="custom-p">There are <b>29 items</b> to complete.</p><p class="custom-p">Rate how well the following statements describe your feelings over the past week.</p></div>',
          name: 'question0',
          type: 'html',
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '1. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              hideNumber: true,
              name: 'question1',
              title: 'I felt valued',
              type: 'rating',
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },

    {
      name: 'page2',
      elements: [
        {
          name: 'test',
          type: 'panel',
          title: '2. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question2',
              type: 'rating',
              title: 'I felt comfortable expressing myself',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
              colCount: 4, // This sets the buttons to be inline if space allows
            },
          ],
        },
      ],
    },

    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '3. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question3',
              type: 'rating',
              title: 'I felt accepted by others',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '4. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question4',
              type: 'rating',
              title: 'I felt understood',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '5. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question5',
              type: 'rating',
              title: 'I felt like others got me',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '6. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question6',
              type: 'rating',

              title: 'I felt respected',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '7. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question7',
              type: 'rating',
              title: 'There was someone who made me feel safe',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '8. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question8',
              type: 'rating',
              title: 'There was someone that I could trust',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '9. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question9',
              type: 'rating',
              title: 'I felt comforted by others',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '10. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question10',
              type: 'rating',
              title: 'I felt heard by others',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '11. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question11',
              type: 'rating',
              title: 'I felt like people would try their best to help me',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '12. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question12',
              type: 'rating',
              title: 'I felt cared for',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '13. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question13',
              type: 'rating',
              title: 'I felt wanted',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '14. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question14',
              type: 'rating',
              title: 'I didn’t feel judged by others',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '15. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question15',
              type: 'rating',
              title: 'I felt able to empathise with other people',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '16. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question16',
              type: 'rating',
              title: 'I felt able to comfort another person if needed',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '17. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question17',
              type: 'rating',
              title: 'I felt compassion for others',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '18. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question18',
              type: 'rating',
              title: 'I wanted to help others relax',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '19. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question19',
              type: 'rating',
              title: 'I felt like I could comfort a loved one',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '20. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question20',
              type: 'rating',
              title: 'I felt so connected to others I wanted to help them',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '21. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question20',
              type: 'rating',
              title: 'I felt caring',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '22. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question20',
              type: 'rating',
              title: 'My heart rate felt steady',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '23. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question20',
              type: 'rating',
              title: 'Breathing felt effortless',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '24. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question20',
              type: 'rating',
              title: 'My voice felt normal',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '25. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question20',
              type: 'rating',
              title: 'My body felt relaxed',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '26. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question20',
              type: 'rating',
              title: 'My stomach felt settled',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '27. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question20',
              type: 'rating',
              title: 'My breathing was steady',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '28. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question20',
              type: 'rating',
              title: 'I felt able to stay still',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '29. Rate how well this statement describes your feelings over the past week:',
          elements: [
            {
              name: 'question20',
              type: 'rating',
              title: 'My face felt relaxed',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Strongly Disagree',
                  value: '1',
                },
                {
                  text: 'Disagree',
                  value: '2',
                },
                {
                  text: 'Neither Agree or Disagree',
                  value: '3',
                },
                {
                  text: 'Agree',
                  value: '4',
                },
                {
                  text: 'Strongly Agree',
                  value: '5',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  title: 'Neuroception of Psychological Safety Scale (NPSS)',
  showProgressBar: 'bottom',
  startSurveyText: 'Start',
  pageNextText: 'Skip',
  goNextPageAutomatic: true,
  questionsOnPageMode: 'questionPerPage',
  firstPageIsStarted: true,
}
