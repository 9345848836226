/**
 * This is a page for view only assessment
 */
import { Container } from '@mui/material'
import React, { useState } from 'react'
import get from 'lodash/get'
import { useMutation } from 'utils/apollo'
import Header from './components/Header'
import MainLayout from 'components/containers/main/Main'
import useGetAssessments from './utils/useGetAssessments'
import { PageLoader } from '../../components'
import { useGetUserWithoutRefresh } from '../../utils/hooks/useGetUser'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { useNavigate, useParams, useLocation, matchPath } from 'react-router-dom'
import { ACTIONS } from './utils/constants'
import { setAssessmentStatus } from '../../store/modules/sessions'
import { autoSaveAssessment } from '../../store/modules/assessments'
import { useGetAssessmentSessions } from 'utils/hooks/useGetSessions'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { ASSESSMENT_STATUS } from 'utils/constants/assessmentStatus'
import { getAssessmentScore } from './utils/get-assessment-score'
import { UPDATE_SESSION } from './constants/gql'

import { Model } from 'survey-core'
import { Survey } from 'survey-react-ui'
import 'survey-core/defaultV2.min.css'
import gad7 from './utils/gad7'
import bpqSf from './utils/bpq-sf'
import dcdq from './utils/dcdq'
import pcl5Week from './utils/pcl5-week'
import npss from './utils/npss'
import psc from './utils/psc'
import bpqAns from './utils/bpq-ans'
import nq from './utils/nq'
import phq from './utils/phq'
import ais from './utils/ais'
import ace from './utils/ace'
import bbcss13 from './utils/bbcss13'
import bbcss12 from './utils/bbcss12'
import { parseMetadata } from './utils/parse-metadata'
import { DefaultLightPanelless } from 'survey-core/themes'
import { useOnValueChange } from 'utils/hooks/useOnValueChange'

const useGetClientUpdateAssessments = ({ userId, sessionId }) => {
  const [updateSession, { loading: updateLoading }] = useMutation(UPDATE_SESSION)
  const { selectedUser, loading: loadingGetUser } = useGetUserWithoutRefresh(userId)
  const loading = updateLoading || loadingGetUser

  if (selectedUser && updateSession) {
    return {
      loading,
      selectedUser,
      updateSession,
    }
  } else {
    return { loading }
  }
}

export default function ClientUpdateAssessments() {
  const navigate = useNavigate()
  const { pathname: assessmentPathname } = useLocation()
  const params = useParams()

  const { IN_PROGRESS, SENT_TO_CLIENT, COMPLETED, isInProgress, isSent } = ASSESSMENT_STATUS

  const sessionId = get(params, 'sessionId', -1)
  const userId = get(params, 'userId', -1)

  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const [onClickLoading, setOnClickLoading] = useState(false)

  const assessmentSessions = useSelector((state) => get(state, 'assessments.sessions', []))
  const filterSession = assessmentSessions.find(({ id }) => id === parseInt(sessionId))
  const productEventId = get(filterSession, 'productEventId', {})

  const productEvents = useSelector((state) => state.assessments.productAssessments)
  const selectedAssessment = productEvents.find(({ id }) => id === productEventId)

  // hooks
  const { loading: loadingGetAssessments } = useGetAssessments({})
  const { loading: loadingHook, selectedUser, updateSession } = useGetClientUpdateAssessments({
    userId,
    sessionId,
  })
  useGetAssessmentSessions()

  const [assessmentData, setAssessmentData] = useState({
    lastVisitedPage: 0,
    sessionData: {},
  })
  useOnValueChange(JSON.stringify({ filterSession }), () => {
    const sessionData = get(filterSession, 'data', {})
    setAssessmentData({
      lastVisitedPage: sessionData?.currentPageNumber || sessionData?.currentPageNo || 0,
      sessionData,
    })
  })

  const loading = onClickLoading || loadingHook || loadingGetAssessments

  const onClick = (eventStatus) => async () => {
    setOnClickLoading(true)
    const currentPageNo = survey.currentPageNo
    const status =
      isSent(assessmentData.sessionData.status) && isInProgress(eventStatus)
        ? SENT_TO_CLIENT
        : eventStatus

    const score = status === 'Completed' ? getAssessmentScore(survey.data, metadata) : undefined
    const data = { answers: { ...survey.data, score }, status, currentPageNo }
    try {
      const session = {
        data: JSON.stringify(data).replace(/'/g, '`'),
        id: parseInt(sessionId),
        metadata: { userAgent: navigator.userAgent },
      }
      await updateSession({ variables: { session } })
      await dispatch(setAssessmentStatus({ sessionId, status }))
      await navigate('/')

      // after completing assessment, client will be redirected to dashboard
      await enqueueSnackbar(ACTIONS[eventStatus].message, {
        variant: ACTIONS[eventStatus].variant,
        action: CloseSnackbarAction,
      })
    } catch (error) {
      await enqueueSnackbar(ACTIONS['error'].message, {
        variant: ACTIONS['error'].variant,
        action: CloseSnackbarAction,
      })
      if (!error.message.includes('Not Authorized')) {
        console.error(error)
      }
    }
  }

  const metadata = get(selectedAssessment, 'metadata', {})
  const title = get(selectedAssessment, 'metadata.assessmentType', '')

  const { assessmentName } = parseMetadata(metadata)
  const mp = {
    'Generalised Anxiety Disorder (GAD-7)': gad7,
    'Body Perception Questionnaire Autonomic Symptoms Short Form (BPQ20-ANS)': bpqAns,
    'Body Perception Questionnaire Short Form (BPQ-SF)': bpqSf,
    'The Developmental Coordination Disorder Questionnaire (DCDQ)': dcdq,
    'Neuroception of Psychological Safety Scale (NPSS)': npss,
    'PTSD Checklist for DSM-5 (PCL-5) - Week': pcl5Week,
    'Pediatric Symptom Checklist (PSC)': psc,
    'Nijmegen Questionnaire (NQ)': nq,
    'Patient Health Questionnaire (PHQ-9)': phq,
    'Athens Insomnia Scale (AIS)': ais,
    'Adverse Childhood Experience for Adults (ACE)': ace,
    'Brain Body Center Sensory Scales (BBCSS) - Full': bbcss13,
    'Brain Body Center Sensory Scales (BBCSS) - Full (Child)': bbcss12,
  }
  const survey = new Model(get(mp, assessmentName, gad7))
  survey.applyTheme(DefaultLightPanelless)

  const currentUserId = useSelector((state) => get(state, 'auth.user.id', -1))
  const isOwnDashboard = parseInt(userId) === currentUserId
  const tabs = [
    {
      text: isOwnDashboard ? (
        'My dashboard'
      ) : (
        <>
          <b>{selectedUser.fullName}</b> {!!selectedUser.email && <>({selectedUser.email})</>}
        </>
      ),

      url: isOwnDashboard ? '/' : `/dashboard/${userId}`,
      isActive: (pathname) =>
        matchPath({ path: isOwnDashboard ? '/' : `/dashboard/${userId}` }, pathname),
    },
    {
      text: 'View Assessment',
      url: assessmentPathname,
      isActive: (pathname) => matchPath({ path: assessmentPathname }, pathname),
    },
  ]

  const autoSave = async (event) => {
    try {
      const surveyData = get(event, 'valuesHash', {})
      const score = getAssessmentScore(surveyData, metadata)
      const data = {
        answers: { ...surveyData, score },
        status: IN_PROGRESS,
        currentPageNo: survey.currentPageNo,
      }
      const session = {
        data: JSON.stringify(data).replace(/'/g, '`'),
        id: parseInt(sessionId),
        metadata: { userAgent: navigator.userAgent },
      }
      await dispatch(autoSaveAssessment({ session }))
    } catch (error) {
      enqueueSnackbar('Unable to save assessment, please refresh page and try again', {
        variant: 'error',
        action: CloseSnackbarAction,
      })
      if (!error.message.includes('Not Authorized')) {
        console.error(error)
      }
    }
  }

  return (
    <MainLayout title="Continue Assessment" tabs={tabs} hideHeader>
      <Container className="py-5">
        {loading && <PageLoader />}
        {!loading && (
          <>
            <Header title={title} onSave={onClick(IN_PROGRESS)} data={assessmentData.sessionData} />
            <Survey
              model={survey}
              mode={'default'}
              data={assessmentData.sessionData?.answers}
              onComplete={onClick(COMPLETED)}
              widthMode="responsive"
              showProgressBar="off"
              showNavigationButtons="bottom"
              goNextPageAutomatic={true}
              onCurrentPageChanged={autoSave}
              currentPageNo={assessmentData.lastVisitedPage}
              questionsOnPageMode="questionPerPage"
            />
          </>
        )}
        <div style={{ height: '100px' }} />
      </Container>
    </MainLayout>
  )
}
