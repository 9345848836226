import React from 'react'
import { Typography, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const AssessmentSuccessPage = () => {
  const navigate = useNavigate()
  const handleContinue = () => {
    navigate('/assessments')
  }
  return (
    <div style={{}}>
      <Stack spacing={2} sx={{ py: 6 }} alignItems="center">
        <Typography component="p" sx={{ width: 'max-content' }} className="custom-p">
          Thank you for completing this assessment, your response has been received.
        </Typography>
        <Typography component="p" sx={{ width: 'max-content', pb: 4 }} className="custom-p">
          Pause, breathe, and take a moment for yourself.
        </Typography>
        <button className="sd-btn" onClick={handleContinue}>
          Continue
        </button>
      </Stack>
    </div>
  )
}

export { AssessmentSuccessPage }
