export default {
  pages: [
    {
      name: 'Intro',
      elements: [
        {
          html:
            '<div><p class="custom-p">There are <b>9 items</b> to complete.</p><p class="custom-p">Over the last two weeks, how often have you been bothered by the following problems?</p></div>',
          name: 'question0',
          type: 'html',
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '1. Over the last two weeks, how often have you been bothered by the following problems?',
          elements: [
            {
              name: 'question1',
              type: 'rating',
              title: 'Little interest or pleasure in doing things',
              hideNumber: true,
              rateValues: [
                { text: 'Not at all', value: 0 },
                { text: 'Several days', value: 1 },
                { text: 'More than half the days', value: 2 },
                { text: 'Nearly every day', value: 3 },
              ],
            },
          ],
        },
        {
          name: 'question2',
          type: 'rating',
          title: 'Feeling down, depressed, or hopeless',
          hideNumber: true,
          rateValues: [
            { text: 'Not at all', value: 0 },
            { text: 'Several days', value: 1 },
            { text: 'More than half the days', value: 2 },
            { text: 'Nearly every day', value: 3 },
          ],
        },
        {
          name: 'question3',
          type: 'rating',
          title: 'Trouble falling or staying asleep, or sleeping too much',
          hideNumber: true,
          rateValues: [
            { text: 'Not at all', value: 0 },
            { text: 'Several days', value: 1 },
            { text: 'More than half the days', value: 2 },
            { text: 'Nearly every day', value: 3 },
          ],
        },
        {
          name: 'question4',
          type: 'rating',
          title: 'Feeling tired or having little energy',
          hideNumber: true,
          rateValues: [
            { text: 'Not at all', value: 0 },
            { text: 'Several days', value: 1 },
            { text: 'More than half the days', value: 2 },
            { text: 'Nearly every day', value: 3 },
          ],
        },
        {
          name: 'question5',
          type: 'rating',
          title: 'Poor appetite or overeating',
          hideNumber: true,
          rateValues: [
            { text: 'Not at all', value: 0 },
            { text: 'Several days', value: 1 },
            { text: 'More than half the days', value: 2 },
            { text: 'Nearly every day', value: 3 },
          ],
        },
        {
          name: 'question6',
          type: 'rating',
          title:
            'Feeling bad about yourself – or that you are a failure or have let yourself or your family down',
          hideNumber: true,
          rateValues: [
            { text: 'Not at all', value: 0 },
            { text: 'Several days', value: 1 },
            { text: 'More than half the days', value: 2 },
            { text: 'Nearly every day', value: 3 },
          ],
        },
        {
          name: 'question7',
          type: 'rating',
          title:
            'Trouble concentrating on things, such as reading the newspaper or watching television',
          hideNumber: true,
          rateValues: [
            { text: 'Not at all', value: 0 },
            { text: 'Several days', value: 1 },
            { text: 'More than half the days', value: 2 },
            { text: 'Nearly every day', value: 3 },
          ],
        },
        {
          name: 'question8',
          type: 'rating',
          title:
            'Moving or speaking so slowly that other people could have noticed?  Or the opposite – being so fidgety or restless that you have been moving around a lot more than usual',
          hideNumber: true,
          rateValues: [
            { text: 'Not at all', value: 0 },
            { text: 'Several days', value: 1 },
            { text: 'More than half the days', value: 2 },
            { text: 'Nearly every day', value: 3 },
          ],
        },
        {
          name: 'question9',
          type: 'rating',
          title: 'Thoughts that you would be better off dead or of hurting yourself in some way',
          hideNumber: true,
          rateValues: [
            { text: 'Not at all', value: 0 },
            { text: 'Several days', value: 1 },
            { text: 'More than half the days', value: 2 },
            { text: 'Nearly every day', value: 3 },
          ],
        },
      ],
    },
  ],
  title: 'Patient Health Questionnaire (PHQ-9)',
  showTitle: true,
  completeText: 'Skip',
  pageNextText: 'Skip',
  showProgressBar: 'bottom',
  goNextPageAutomatic: true,
  questionsOnPageMode: 'questionPerPage',
  startSurveyText: 'Start',
  firstPageIsStarted: true,
}
