export default {
  pages: [
    {
      name: 'Intro',
      elements: [
        {
          html:
            '<h4>Note to Parent:</h4><p>As we start to consider the delivery of SSP for your child, it will be helpful if you could please answer the following questions as honestly as you can. Please be assured that the information you share with me is confidential.</p><p> As you will see, this is different from more traditional assessments or questionnaires you may have completed in the past. There are no rights or wrongs here. Instead, it is designed to give me more information about your child’s current circumstances. You will see that there are two sections to this form for you to complete: one for you to share details about your child’s experiences, and the other about resources available to them.</p><p> The details you share with me will help us work together to explore what will be the best way to move forward in your child’s SSP journey. There are spaces for you to write more information that you think may be relevant or helpful for me to know.</p><h4>Ready to Start?</h4>',
          name: 'question1',
          type: 'html',
        },
      ],
    },
    {
      name: 'Sound Sensitivity (i)',
      title: '1. Sound sensitivity',
      elements: [
        {
          name: 'Sound Sensitivity (i)',
          type: 'panel',
          elements: [
            {
              html: '<h4>Check all that apply and provide details:</h4>',
              name: 'Check all that apply and provide details',
              type: 'html',
            },
            {
              name: 's2',
              type: 'checkbox',
              choices: [
                {
                  text:
                    'Auditory hypersensitivity/hyperacusis - certain frequencies of sound are strongly disliked; noisy environments are avoided',
                  value: 'c1',
                },
                {
                  text: 'Misophonia - specific sounds have to be avoided or feel unbearable',
                  value: 'c2',
                },
                {
                  text:
                    'Auditory Processing Disorder - hearing is normal but processing and understanding what is heard is compromised',
                  value: 'c3',
                },
                {
                  text: 'Difficulty hearing speech when there is background noise',
                  value: 'c4',
                },
                {
                  text: 'Other (describe)',
                  value: 'c5',
                },
              ],
              description: 'Check all that apply and provide details:',
            },
            {
              html: '<h4>Details</h4>',
              name: 'Details',
              type: 'html',
            },
            {
              name: 'question_1_2',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
        },
      ],
      questionTitleLocation: 'hidden',
    },
    {
      name: 'General Sensory Sensitivity (i)',
      title: '2. General sensory sensitivity',
      elements: [
        {
          name: 'General sensory sensitivity',
          type: 'panel',
          elements: [
            {
              html: '<h4>Check all that apply and provide details:</h4>',
              name: 'Check all that apply and provide details',
              type: 'html',
            },
            {
              name: 's3',
              type: 'checkbox',
              title: 'a. General sensory sensitivity',
              choices: [
                { text: 'Certain clothing and textures are irritating', value: 'c1' },
                { text: 'Certain tastes are overwhelming', value: 'c2' },
                { text: 'Extreme response to certain smells', value: 'c3' },
                { text: 'Difficulty knowing where limbs are in space', value: 'c4' },
                { text: 'Extreme motion sickness', value: 'c5' },
                { text: 'Hypersensitivity to light', value: 'c6' },
                {
                  text: 'Inability to accommodate and adjust to certain sensations',
                  value: 'c7',
                },
                { text: 'Other (describe)', value: 'c8' },
              ],
              colCount: 1,
              hideNumber: true,
              titleLocation: 'hidden',
            },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            {
              name: 's3_2',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
      ],
    },
    {
      name: 'Prior Listening Experience (i)',
      title: '3. Prior Listening Experience',
      elements: [
        {
          name: 'a. Prior completion of SSP or other listening therapies?',
          type: 'panel',
          elements: [
            {
              html: '<h4>a. Prior completion of SSP or other listening therapies?</h4>',
              name: 'a. Prior completion of SSP or other listening therapies?',
              type: 'html',
            },
            {
              name: 's4_1_1',
              type: 'boolean',
              title: 'a. Prior completion of SSP or other listening therapies?',
              valueTrue: '0',
              hideNumber: true,
              valueFalse: '1',
            },
            {
              html: '<h4>Please describe your child‘s previous experience with the SSP</h4>',
              name: 'Please describe your child‘s previous experience with the SSP',
              type: 'html',
            },
            {
              name: 's4_1_2',
              type: 'comment',
              title: 'Please describe your child‘s previous experience with the SSP',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
        {
          name: 'b. How does music affect your child generally?',
          type: 'panel',
          elements: [
            {
              html: '<h4>b. How does music affect your child generally?</h4>',
              name: 'b. How does music affect your child generally?',
              type: 'html',
            },
            {
              name: 's4_2_1',
              type: 'rating',
              title: 'b. How does your child engage with music in their life?',
              rateValues: [
                {
                  value: 1,
                  text: 'Calming, Grounding',
                },
                2,
                3,
                4,
                5,
                6,
                {
                  value: 7,
                  text: 'Aggravating, Irritating',
                },
              ],
              hideNumber: true,
            },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            {
              name: 's4_2_2',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
        {
          name: 'c. How does your child engage with music in their life?',
          type: 'panel',
          elements: [
            {
              html: '<h4>c. How does your child engage with music in their life?</h4>',
              name: 'c. How does your child engage with music in their life?',
              type: 'html',
            },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            {
              name: 's4_3_1',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
        {
          name: 'd. Are there times when your child prefer silence?',
          type: 'panel',
          elements: [
            {
              html: '<h4>d. Are there times when your child prefer silence?</h4>',
              name: 'd. Are there times when your child prefer silence?',
              type: 'html',
            },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            {
              name: 's4_4_1',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
      ],
    },
    {
      name: 'Nervous System Tendency (i)',
      title: '4. Nervous System Tendency',
      elements: [
        {
          name: 'Nervous System Tendency (i)',
          type: 'panel',
          elements: [
            {
              html:
                '<p>When reacting to distressing events, it is normal to quickly move out of a restful (ventral vagal) state of social engagement and connection, and into a survival state.</p><p>There are two general types of survival responses or defensive states and we usually tend to move towards one more than the other. The two states are:</p><p><b>-Activation/mobilization (sympathetic hyper-arousal)</b></p><p><b>-Shutdown/immobilization (dorsal vagal hypo-arousal)</b></p><p>Please use the lists of descriptors on the next page to help you consider which state your child tends toward more.</p><p><i>Please note that while these words may have a negative tone, both systems are important and valuable supports of the body. You may find it helpful to select the words that apply to your child.</i></p>',
              name: 's8',
              type: 'html',
            },
          ],
        },
        {
          name: 'a. In sympathetic hyper-arousal, (activation/mobilization) they might feel:',
          type: 'panel',
          elements: [
            {
              html:
                '<h4>a. In sympathetic hyper-arousal, (activation/mobilization) they might feel:</h4>',
              name: 's9_i',
              type: 'html',
            },
            {
              name: 's9_1',
              type: 'checkbox',
              choices: [
                { text: 'Worried', value: 'a' },
                { text: 'Jittery/jumpy', value: 'b' },
                { text: 'Unable to relax', value: 'c' },
                { text: 'Unable to take things easily', value: 'd' },
                { text: 'Cranky', value: 'e' },
                { text: 'Wary', value: 'f' },
                { text: 'Impulsive/reactive', value: 'g' },
                { text: 'Short tempered', value: 'h' },
                { text: 'Inflexible', value: 'i' },
                { text: '"Boiling" feelings', value: 'j' },
                { text: 'Consumed by racing thoughts', value: 'k' },
                { text: 'Heart beating fast', value: 'l' },
                { text: 'Rapid breathing', value: 'm' },
                { text: 'Unable to Focus', value: 'n' },
                { text: 'Too much energy', value: 'o' },
                { text: 'Other (describe)', value: 'r' },
              ],
              colCount: 5,
              hideNumber: true,
            },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            {
              name: 's9_2',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
        {
          name: 'b. In sympathetic hyper-arousal they might exhibit the following behaviors:',
          type: 'panel',
          elements: [
            {
              html:
                '<h4>b. In sympathetic hyper-arousal they might exhibit the following behaviors:</h4>',
              name: 'b. In sympathetic hyper-arousal they might exhibit the following behaviors:',
              type: 'html',
            },
            {
              name: 's10_1',
              type: 'checkbox',
              choices: [
                { text: 'Quick to become frustrated', value: 'a' },
                { text: 'Argumentative', value: 'b' },
                { text: 'Name calling or accusing', value: 'c' },
                { text: 'Fidgety/ constant movement', value: 'd' },
                { text: 'Sticky thoughts', value: 'e' },
                { text: 'Frequent bursts of anger & hostility', value: 'f' },
                { text: 'Resistance to suggestions', value: 'g' },
                { text: 'Pushing boundaries', value: 'h' },
                { text: 'Biting, hiting, throwing', value: 'i' },
                { text: 'Impulsive decisions or actions', value: 'j' },
                { text: 'Over-reactive responses', value: 'k' },
                { text: 'Walking or running away from situations', value: 'l' },
                { text: 'Crying, screaming, yelling', value: 'm' },
                { text: 'Threatening words of gesture', value: 'n' },
                { text: 'Tantrums', value: 'o' },
                { text: 'Other (describe)', value: 'p' },
              ],
              colCount: 5,
              hideNumber: true,
            },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            {
              name: 's10_2',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
        {
          name: 's11',
          type: 'panel',
          elements: [
            {
              html: '<h4>c. In dorsal vagal hypo-arousal your child might feel:</h4>',
              name: 's11_i',
              type: 'html',
            },
            {
              name: 's11_1',
              type: 'checkbox',
              choices: [
                { text: 'Low', value: 'c1' },
                { text: 'Shut down', value: 'c2' },
                { text: 'Shame', value: 'c3' },
                { text: 'Numb', value: 'c4' },
                { text: 'Overwhelmed', value: 'c5' },
                { text: 'Frozen', value: 'c6' },
                { text: 'Sad', value: 'c7' },
                { text: 'Depressed', value: 'c8' },
                { text: 'Lifeless', value: 'c9' },
                { text: 'Sluggish', value: 'c10' },
                { text: 'Heavy', value: 'c11' },
                { text: 'Isolated', value: 'c12' },
                { text: 'No or slow energy', value: 'c13' },
                { text: 'Want to curl up & hide', value: 'c14' },
                { text: 'Avoidant', value: 'c15' },
                { text: 'Withdrawn', value: 'c16' },
                { text: 'Disconnected ', value: 'c17' },
                { text: 'Unreachable', value: 'c18' },
                { text: 'Other (describe)', value: 'c19' },
              ],
              colCount: 5,
              hideNumber: true,
            },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            {
              name: 's11_2',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
        {
          name: 's12',
          type: 'panel',
          elements: [
            {
              html:
                '<h4>d. In dorsal vagal hypo-arousal your child might exhibit the following behaviors:</h4>',
              name: 's12_i',
              type: 'html',
            },
            {
              name: 's12_1',
              type: 'checkbox',
              choices: [
                { text: 'Disengaging or fading away', value: 'c1' },
                { text: 'Low muscle tone', value: 'c2' },
                { text: 'Seeming to not hear what others say', value: 'c3' },
                { text: 'Hiding or shrinking', value: 'c4' },
                { text: 'Letting others speak for them', value: 'c5' },
                { text: 'Slumped posture', value: 'c6' },
                { text: 'Low curiosity/ lack of playfulness', value: 'c7' },
                { text: 'Drowsy/tired', value: 'c8' },
                { text: 'Retreating from social connection', value: 'c9' },
                { text: 'Avoiding eye contact', value: 'c10' },
                { text: 'Recoiling from touch', value: 'c11' },
                { text: 'Giving up', value: 'c12' },
                { text: 'Giving in', value: 'c13' },
                { text: 'Unhealthy digestion', value: 'c14' },
                { text: 'Flat facial expression & monotone voice', value: 'c15' },
                { text: 'Other (describe)', value: 'c16' },
              ],
              colCount: 5,
              hideNumber: true,
            },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            {
              name: 's12_2',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
        {
          name: 's13',
          type: 'panel',
          elements: [
            {
              html: '<h4>e. When reacting to distressing events my child tends more towards:</h4>',
              name: 's13_i',
              type: 'html',
            },
            // {
            //   name: 's4_2_1',
            //   type: 'rating',
            //   title: 'b. How does your child engage with music in their life?',
            //   rateValues: [
            //     {
            //       value: 1,
            //       text: 'Calming, Grounding',
            //     },
            //     2,
            //     3,
            //     4,
            //     5,
            //     6,
            //     {
            //       value: 7,
            //       text: 'Aggravating, Irritating',
            //     },
            //   ],
            //   hideNumber: true,
            // },
            {
              name: 's13_1',
              type: 'rating',
              rateValues: [
                { text: 'Sympathetic hyper-arousal &#10; (activation/ mobilization)', value: '1' },
                2,
                3,
                4,
                5,
                6,
                { text: 'Dorsal vagal hypo-arousal (shutdown/ immobilization)', value: '7' },
              ],
              hideNumber: true,
            },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            {
              name: 's13_2',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
        {
          name: 's14',
          type: 'panel',
          elements: [
            {
              html:
                '<p>In contrast to the two defensive states above, when your child is at rest and cues of safety outweigh any cues of danger in their environment, they will be in a ventral vagal state where they can be socially engaged, calm, have impulse control, and generally experience feelings of wellbeing.\n\nPlease use the lists on the next page to help you consider which state your child tends toward more.</p>',
              name: 's14',
              type: 'html',
            },
          ],
        },
        {
          name: 's15',
          type: 'panel',
          elements: [
            {
              html: '<h4>f. In a ventral vagal calm state, your child might feel:</h4>',
              name: 's15_i',
              type: 'html',
            },
            {
              name: 's15_1',
              type: 'checkbox',
              title: 'Nervous System Tendency',
              choices: [
                { text: 'Safe', value: 'c1' },
                { text: 'Alert', value: 'c2' },
                { text: 'Proud', value: 'c3' },
                { text: 'Interested', value: 'c4' },
                { text: 'Curious', value: 'c5' },
                { text: 'Calm', value: 'c6' },
                { text: 'Content', value: 'c7' },
                { text: 'Engaged', value: 'c8' },
                { text: 'Active', value: 'c9' },
                { text: 'Trusting', value: 'c10' },
                { text: 'Willing', value: 'c11' },
                { text: 'Accessible', value: 'c12' },
                { text: 'Accepting', value: 'c13' },
                { text: 'Ready to participate', value: 'c14' },
                { text: 'Comfortable', value: 'c15' },
                { text: 'Attuned', value: 'c16' },
                { text: 'Connected', value: 'c17' },
                { text: 'Confident', value: 'c18' },
                { text: 'Cared for', value: 'c19' },
                { text: 'Grateful', value: 'c20' },
                { text: 'Other (describe)', value: 'c21' },
              ],
              colCount: 5,
              hideNumber: true,
            },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            {
              name: 's15_2',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
        {
          name: 's16',
          type: 'panel',
          elements: [
            {
              html:
                '<h4>g. In a ventral vagal calm state, your child might exhibit the following behaviors:</h4>',
              name: 's16_i',
              type: 'html',
            },
            {
              type: 'checkbox',
              title: 'Nervous System Tendency',
              choices: [
                { text: 'Engaged and aware', value: 'c1' },
                { text: 'Reciprocal play', value: 'c2' },
                { text: 'Interest in exploring', value: 'c3' },
                { text: 'Advocating & doing things for themself', value: 'c4' },
                { text: 'Speaking their mind', value: 'c5' },
                { text: 'Upright posture', value: 'c6' },
                { text: 'Curious and playful', value: 'c7' },
                { text: 'Ready to learn', value: 'c8' },
                { text: 'Interested in social connection', value: 'c9' },
                { text: 'Maintaining eye contact', value: 'c10' },
                { text: 'Affectionate', value: 'c11' },
                { text: 'Wanting to do things for themself', value: 'c12' },
                { text: 'Perseverance', value: 'c13' },
                { text: 'Healthy digestion', value: 'c14' },
                { text: 'Good facial expression & vocal prosody', value: 'c15' },
                { text: 'Other (describe)', value: 'c16' },
              ],
              colCount: 5,
              hideNumber: true,
            },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            {
              name: 's16_2',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
        {
          name: 's17',
          type: 'panel',
          elements: [
            {
              html:
                '<h4>h. I have observed my child feeling safe and in a ventral vagal state and can relate to these descriptors:</h4>',
              name: 's17_i',
              type: 'html',
            },
            {
              //   name: 's4_2_1',
              //   type: 'rating',
              //   title: 'b. How does your child engage with music in their life?',
              //   rateValues: [
              //     {
              //       value: 1,
              //       text: 'Calming, Grounding',
              //     },
              //     2,
              //     3,
              //     4,
              //     5,
              //     6,
              //     {
              //       value: 7,
              //       text: 'Aggravating, Irritating',
              //     },
              //   ],
              //   hideNumber: true,
              type: 'rating',
              rateValues: [
                { text: 'Agree', value: '1' },
                { text: ' 2', value: '2' },
                { text: ' 3', value: '3' },
                { text: ' Unsure', value: '4' },
                { text: ' 5', value: '5' },
                '6',
                { text: 'Disagree', value: '7' },
              ],
              hideNumber: true,
            },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            {
              name: 's17_2',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
      ],
    },
    {
      name: 's18',
      title: '5. For Parent: Your Own Nervous System Tendency',
      elements: [
        {
          name: 's18',
          type: 'panel',
          elements: [
            {
              html:
                '<p> Just as your child’s nervous system tendency can help to guide their SSP facilitation plan, it is also helpful to know what your tendency is as a parent since this may also be a consideration in the approach. So please respond to the following questions - this time for yourself.</p><br/></p>Please use the lists of descriptors on the next page to help you consider which you tend more toward.</p>',
              name: 's18',
              type: 'html',
            },
          ],
        },
        {
          name: 's19',
          type: 'panel',
          elements: [
            {
              html:
                '<h4>a. In sympathetic hyper-arousal, (activation/mobilization) you might feel:</h4>',
              name: 's19_i',
              type: 'html',
            },
            {
              name: 's19_1',
              type: 'checkbox',
              choices: [
                { text: 'Anxious', value: 'c1' },
                { text: 'Agitated', value: 'c2' },
                { text: 'Restless', value: 'c3' },
                { text: 'Tense', value: 'c4' },
                { text: 'Irritable', value: 'c5' },
                { text: 'Hypervigilant', value: 'c6' },
                { text: 'Impulsive', value: 'c7' },
                { text: 'Uptight', value: 'c8' },
                { text: 'Rigid', value: 'c9' },
                { text: 'Rage', value: 'c10' },
                { text: 'Consumed by racing thoughts', value: 'c11' },
                { text: 'Racing heart', value: 'c12' },
                { text: 'Rapid breathing', value: 'c13' },
                { text: 'Too much energy', value: 'c14' },
                { text: 'Reactive ', value: 'c15' },
                { text: 'Stressed', value: 'c16' },
                { text: 'Other (describe)', value: 'c17' },
              ],
              colCount: 5,
              hideNumber: true,
            },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            {
              name: 's19_2',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
        {
          name: 's20',
          type: 'panel',
          elements: [
            {
              html:
                '<h4>b. In sympathetic hyper-arousal you might exhibit the following behaviors:</h4>',
              name: 's20_i',
              type: 'html',
            },
            {
              name: 's20_1',
              type: 'checkbox',
              choices: [
                { text: 'Quick to become frustrated', value: 'c1' },
                { text: 'Use of loud voice', value: 'c2' },
                { text: 'Name calling or making accusatory remarks', value: 'c3' },
                { text: 'Fidgety/ constant movement', value: 'c4' },
                { text: 'Intrusive thoughts', value: 'c5' },
                { text: 'Frequent bursts of anger or hostility', value: 'c6' },
                { text: 'No time or patience to make conversation', value: 'c7' },
                { text: 'Confrontation al or controlling', value: 'c8' },
                { text: 'Angry or aggressive behavior', value: 'c9' },
                { text: 'Impulsive decisions or actions', value: 'c10' },
                { text: 'Over-reactive responses', value: 'c11' },
                { text: 'Loss of patience', value: 'c12' },
                { text: 'Panic attacks', value: 'c13' },
                { text: 'Perseverative thoughts', value: 'c14' },
                { text: 'Feeling defensive', value: '15' },
                { text: 'Other (describe)', value: 'c16' },
              ],
              colCount: 5,
              hideNumber: true,
            },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            {
              name: 's20_2',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
        {
          name: 's21',
          type: 'panel',
          elements: [
            {
              html: '<h4>c. In dorsal vagal hypo-arousal you might feel:</h4>',
              name: 's20_i',
              type: 'html',
            },
            {
              name: 's21',
              type: 'checkbox',
              choices: [
                { text: 'Low', value: 'c1' },
                { text: 'Shut down', value: 'c2' },
                { text: 'Shame', value: 'c3' },
                { text: 'Numb', value: 'c4' },
                { text: 'Overwhelmed', value: 'c5' },
                { text: 'Frozen', value: 'c6' },
                { text: 'Sad', value: 'c7' },
                { text: 'Depressed', value: 'c8' },
                { text: 'Lifeless', value: 'c9' },
                { text: 'Sluggish', value: 'c10' },
                { text: 'Heavy', value: 'c11' },
                { text: 'Isolated', value: 'c12' },
                { text: 'No or slow energy', value: 'c13' },
                { text: 'Want to curl up & hide', value: 'c14' },
                { text: 'Avoidant', value: 'c15' },
                { text: 'Withdrawn', value: 'c16' },
                { text: 'Disconnected ', value: 'c17' },
                { text: 'Alone', value: 'c18' },
                { text: 'Hopeless', value: 'c19' },
                { text: 'Unreachable', value: 'c20' },
                { text: 'Other (describe)', value: 'c21' },
              ],
              colCount: 5,
              hideNumber: true,
            },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            {
              name: 's21_2',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
        {
          name: 's22',
          type: 'panel',
          elements: [
            {
              html:
                '<h4>d. In dorsal vagal hypo-arousal you might exhibit the following behaviors:</h4>',
              name: 's22_i',
              type: 'html',
            },
            {
              name: 's22_1',
              type: 'checkbox',
              choices: [
                { text: 'Zoning out', value: 'c1' },
                { text: 'Feeling outside of awareness', value: 'c2' },
                { text: 'Withdrawing from connection', value: 'c3' },
                { text: 'Hiding or shrinking', value: 'c4' },
                { text: 'Letting others speak up and lead', value: 'c5' },
                { text: 'Collapsed posture', value: 'c6' },
                { text: 'Shutdown behavior', value: 'c7' },
                { text: 'Depleted of energy', value: 'c8' },
                { text: 'Socially isolating', value: 'c9' },
                { text: 'Averting eye contact', value: 'c10' },
                { text: 'Avoiding touch', value: 'c11' },
                { text: 'Giving up', value: 'c12' },
                { text: 'Giving in ', value: 'c13' },
                { text: 'Unhealthy digestion', value: 'c14' },
                { text: 'Flat facial expression & monotone voice', value: 'c15' },
                { text: 'Other (describe)', value: 'c16' },
              ],
              colCount: 5,
              hideNumber: true,
            },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            {
              name: 's22_2',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
        {
          name: 's23',
          type: 'panel',
          elements: [
            {
              html:
                '<h4>e. After comparing the feelings and behaviors of sympathetic activation and parasympathetic dorsal vagal shutdown, when reacting to distressing events I tend more toward:</h4>',
              name: 's23_i',
              type: 'html',
            },
            {
              name: 's23_1',
              type: 'rating',
              rateValues: [
                { text: 'Sympathetic hyper-arousal (activation/ mobilization)', value: '1' },
                { text: ' 2 ', value: '2' },
                { text: ' 3 ', value: '3' },
                { text: ' 4 ', value: '4' },
                { text: ' 5 ', value: '5' },
                { text: ' 6 ', value: '6' },
                { text: 'Dorsal vagal hypo-arousal (shutdown/ immobilization)', value: '7' },
              ],
              hideNumber: true,
            },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            {
              name: 's23_2',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
      ],
    },
    {
      name: 'p6',
      title: '6. Child Resources',
      elements: [
        {
          name: 's24',
          type: 'panel',
          elements: [
            {
              html: '<h4>a. Describe your general feeling at home:</h4>',
              name: 's24_i',
              type: 'html',
            },
            {
              name: 's24_1',
              type: 'rating',
              title: 'Child Resources',
              rateValues: [
                { text: 'Calm', value: '1' },
                { text: ' 2 ', value: '2' },
                { text: ' 3 ', value: '3' },
                { text: ' 4 ', value: '4' },
                { text: ' 5 ', value: '5' },
                { text: ' 6 ', value: '6' },
                { text: 'Chaotic', value: '7' },
              ],
            },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            {
              name: 's24_2',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
        {
          name: 's25',
          type: 'panel',
          elements: [
            {
              html: '<h4>b. Describe the noise levels at home:</h4>',
              name: 's25_i',
              type: 'html',
            },
            {
              name: 's25',
              type: 'rating',
              rateValues: [
                { text: 'Peaceful, Quiet', value: '1' },
                { text: ' 2 ', value: '2' },
                { text: ' 3 ', value: '3' },
                { text: ' 4 ', value: '4' },
                { text: ' 5 ', value: '5' },
                { text: ' 6 ', value: '6' },
                { text: 'Frenzied, Loud', value: '7' },
              ],
            },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            {
              name: 's25_2',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
        {
          name: 's26',
          type: 'panel',
          elements: [
            {
              html: '<h4>c. Describe the people in your home:</h4>',
              name: 's26_i',
              type: 'html',
            },
            {
              name: 's26',
              type: 'rating',
              rateValues: [
                { text: 'Supportive', value: '1' },
                { text: ' 2 ', value: '2' },
                { text: ' 3', value: '3' },
                { text: ' 4', value: '4' },
                { text: ' 5', value: '5' },
                { text: ' 6', value: '6' },
                { text: 'Unpredictable', value: '7' },
              ],
              hideNumber: true,
            },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            {
              name: 's26_2',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
        {
          name: 's27',
          type: 'panel',
          elements: [
            {
              html: '<h4>d. Please share any details about your home environment:</h4>',
              name: 's27_i',
              type: 'html',
            },
            {
              name: 's27_1',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
      ],
    },
    {
      name: 'p7',
      title: '7. Access to Support (Co-regulation) from Others',
      elements: [
        {
          name: 's28',
          type: 'panel',
          elements: [
            {
              html:
                '<h4>a. Will a reliable, caring adult be able to support this child’s experience with the SSP and to co-regulate with them?</h4>',
              name: 's28_i',
              type: 'html',
            },
            { name: 's28_1', type: 'boolean', valueTrue: '0', hideNumber: true, valueFalse: '1' },
            { html: '<h4>Who will this be?</h4>', name: 'Who', type: 'html' },
            {
              name: 's28_2',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
        {
          name: 's29',
          type: 'panel',
          elements: [
            {
              html:
                '<h4>b. Will you and your child have access to the same quiet space without interruptions for the SSP listening sessions during remote delivery?</h4>',
              name: 's29_i',
              type: 'html',
            },
            { name: 's29_1', type: 'boolean', valueTrue: '0', hideNumber: true, valueFalse: '1' },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            {
              name: 's29_2',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
        {
          name: 's30',
          type: 'panel',
          elements: [
            {
              html:
                '<h4>c. Will your child be supported to practice self-regulation between sessions and after completing SSP? Will they have access to healthy coregulation?</h4>',
              name: 's30_i',
              type: 'html',
            },
            { name: 's30_1', type: 'boolean', valueTrue: '0', hideNumber: true, valueFalse: '1' },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            {
              name: 's30_2',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
        {
          name: 's31',
          type: 'panel',
          elements: [
            {
              html:
                '<h4>d. Are the relationships your child has with the people in their life generally supportive?</h4>',
              name: 's31_i',
              type: 'html',
            },
            { name: 's31_1', type: 'boolean', valueTrue: '0', hideNumber: true, valueFalse: '1' },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            {
              name: 's31_2',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
        {
          name: 's32',
          type: 'panel',
          elements: [
            {
              html:
                '<h4>e. Access to other nurturing and supportive resources (self-regulation):</h4>',
              name: 's32_i',
              type: 'html',
            },
            {
              name: 's32_1',
              type: 'checkbox',
              choices: [
                { text: 'Nature, open spaces, park, or yard', value: 'c1' },
                { text: 'Pet', value: 'c2' },
                { text: 'Sport or playful movement, dance, gymnastics', value: 'c3' },
                {
                  text: 'Simple breathing exercises, mindfulness or guided imagery, yoga',
                  value: 'c4',
                },
                { text: 'Singing or playing a wind instrument', value: 'c5' },
                {
                  text:
                    'Creative activities such as art, drama, or music (other than singing or wind instruments)',
                  value: 'c6',
                },
                { text: 'Other (describe)', value: 'c7' },
              ],
              hideNumber: true,
            },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            {
              name: 's32_2',
              type: 'comment',
              title: 'Details',
              hideNumber: true,
              placeHolder: 'Enter text',
            },
          ],
          questionTitleLocation: 'hidden',
        },
      ],
    },
    {
      name: 'p8',
      title: '8. Unforseen Circumstances',
      elements: [
        {
          html:
            '<p>Although there is always the possibility of unforeseen circumstances, it is helpful to know if there are any significant events likely to happen in your child’s life or those close to them which may have an impact on their ability to benefit from SSP. It is helpful to be aware of these in advance, if possible, so they can be factored into the timing of SSP delivery.</p>',
          name: 'p8_i',
          type: 'html',
        },
        {
          name: 's33',
          type: 'panel',
          elements: [
            {
              html:
                '<h4>a. Please provide details of any events happening or expected in the next few weeks or months so these can be considered. Examples are: exams, change of school or teacher, recent or upcoming move, friendship stress or trouble with peers, illness, new sibling, or recent loss of a family member or pet.</h4>',
              name: 's33_i',
              type: 'html',
            },
            { name: 's33_1', type: 'comment', hideNumber: true, placeHolder: 'Enter text' },
          ],
          questionTitleLocation: 'hidden',
        },
        {
          name: 's34',
          type: 'panel',
          elements: [
            {
              html:
                '<h4>b. In general, how would you assess your child’s and your family’s current situation and experience? Does their life and world feel comfortable?</h4>',
              name: 's34_i',
              type: 'html',
            },
            {
              name: 's34_1',
              type: 'rating',
              rateValues: [
                { text: 'Comfortable', value: '1' },
                { text: ' 2 ', value: '2' },
                { text: ' 3 ', value: '3' },
                { text: ' 4 ', value: '4' },
                { text: ' 5 ', value: '5' },
                { text: ' 6 ', value: '6' },
                { text: 'Unsettled', value: '7' },
              ],
              hideNumber: true,
            },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            { name: 's34_2', type: 'comment', hideNumber: true, placeHolder: 'Enter text' },
          ],
          questionTitleLocation: 'hidden',
        },
      ],
    },
    {
      name: 'p9',
      title: '9. Engagement',
      elements: [
        {
          html:
            '<p>Before starting your child’s SSP journey, it is important you understand that this is a process you are engaging in together. It may require extra support of your child as their nervous system shifts and repatterns. Although the listening is passive, the work to sustain the experience is intentional and requires active engagement from you throughout.</p>',
          name: 'p9_i',
          type: 'html',
        },
        {
          name: 's35',
          type: 'panel',
          elements: [
            {
              html:
                '<h4>a. Do you have a willingness to engage and participate fully with your child in the process with me as your SSP provider, and understand that the SSP is not a quick fix, or a stand-alone therapy?</h4>',
              name: 's35_i',
              type: 'html',
            },
            {
              name: 's34_1',
              type: 'rating',
              rateValues: [
                { text: 'I’m all in', value: '1' },
                { text: ' 2 ', value: '2' },
                { text: ' 3 ', value: '3' },
                { text: ' 4 ', value: '4' },
                { text: ' 5 ', value: '5' },
                { text: ' 6 ', value: '6' },
                { text: 'I’m skeptical', value: '7' },
              ],
              hideNumber: true,
            },
            { html: '<h4>Details</h4>', name: 'Details', type: 'html' },
            { name: 's35_2', type: 'comment', hideNumber: true, placeHolder: 'Enter text' },
          ],
          questionTitleLocation: 'hidden',
        },
      ],
    },
  ],
  showProgressBar: 'bottom',
  questionsOnPageMode: 'questionPerPage',
  clearInvisibleValues: 'none',
  firstPageIsStarted: true,
  startSurveyText: 'Start',
  title: 'Unyte SSP Intake Form (Child)',
  showTitle: true,
}
