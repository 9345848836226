/**
 * This is a page for new assessment
 */
import React, { useMemo, useState } from 'react'
import { Stack, Box } from '@mui/material'
import { useOutletContext, useParams, useNavigate } from 'react-router'
import { getAssessmentScore } from './utils/get-assessment-score'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import { parseMetadata } from './utils/parse-metadata'
import { useMutation } from '@apollo/client'
import { CREATE_SESSION } from './constants/gql'
import { useSnackbar } from 'notistack'
import { ACTIONS } from './utils/constants'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { resetFilter } from 'store/modules/assessments'

// survey js
import { Model } from 'survey-core'
import { Survey } from 'survey-react-ui'
import gad7 from './utils/gad7'
import bpqSf from './utils/bpq-sf-2'
import dcdq2 from './utils/dcdq2'
import pcl5Week2 from './utils/pcl5-week2'
import npss2 from './utils/npss2'
import psc2 from './utils/psc2'
import bpqAns from './utils/bpq-ans2'
import nq from './utils/nq'
import ais2 from './utils/ais2'
import ace from './utils/ace2'
import bbcss13 from './utils/bbcss13-2'
import bbcss12 from './utils/bbcss12-2'
import intakeAdult from './utils/intakeAdult'
import intakeChild from './utils/intakeChild'
import phq2 from './utils/phq2'
import cats36 from './utils/cats36-2'
import cats717 from './utils/cats717-2'
import { AssessmentSuccessPage } from './assessment-success-page'
import { themeJson } from './utils/theme'
import { ScrollableComponent } from 'components/scrollable-component'

import 'survey-core/defaultV2.min.css'
import './styles/nav-button-intro-outro.css'

export default function CreateAssessment() {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isComplete, setIsComplete] = useState(false)
  const { setLoadingState, onLoadKPI, refetch, setLoadingStateWithTimer } = useOutletContext()

  // get params variables
  const { userId, productEventId: _productEventId } = useParams()
  const productEventId = parseInt(_productEventId)

  // get metadata
  const productEvents = useSelector((state) => state.assessments.productAssessments)
  const productId = get(
    productEvents.find(({ id }) => id === productEventId),
    'productId',
    null
  )
  const metadata = get(
    productEvents.find(({ id }) => id === productEventId),
    'metadata',
    {}
  )
  const { assessmentName } = parseMetadata(metadata)

  // gql
  const [createSession] = useMutation(CREATE_SESSION)

  // if first page, don't show next for intake forms
  const mp = {
    'Generalised Anxiety Disorder (GAD-7)': gad7,
    'Body Perception Questionnaire Autonomic Symptoms Short Form (BPQ20-ANS)': bpqAns,
    'Body Perception Questionnaire Short Form (BPQ-SF)': bpqSf,
    'The Developmental Coordination Disorder Questionnaire (DCDQ)': dcdq2,
    'Neuroception of Psychological Safety Scale (NPSS)': npss2,
    'PTSD Checklist for DSM-5 (PCL-5) - Week': pcl5Week2,
    'Pediatric Symptom Checklist (PSC) - Caregiver': psc2,
    'Nijmegen Questionnaire (NQ)': nq,
    'Patient Health Questionnaire (PHQ-9)': phq2,
    'Athens Insomnia Scale (AIS)': ais2,
    'Adverse Childhood Experience for Adults (ACE)': ace,
    'Brain Body Center Sensory Scales (BBCSS) - Full': bbcss13,
    'Brain Body Center Sensory Scales (BBCSS) - Full (Child)': bbcss12,
    'Unyte SSP Intake Form (Adult)': intakeAdult,
    'Unyte SSP Intake Form (Child)': intakeChild,
    'Child and Adolescent Trauma Screen (CATS) (Child) - Month': cats36,
    'Child and Adolescent Trauma Screen (CATS) (Adolescent) - Month': cats717,
  }

  // save assessment and refresh dashboard to see current assessment
  const handleSave = async () => {
    try {
      const surveyData = survey.getData()
      const data = {
        answers: { ...surveyData },
        status: 'In Progress',
        currentPageNo: survey.currentPageNo,
      }
      const session = {
        data: JSON.stringify(data).replace(/'/g, '`'),
        productId,
        productEventId,
        startedAt: new Date().toISOString(),
        type: 'answers',
        userId: parseInt(userId) || parseInt(window.location.pathname.split('/').at(3)),
        metadata: { userAgent: navigator.userAgent },
      }
      await handleCreate(session)
    } finally {
      navigate('/assessments')
    }
  }

  const survey = useMemo(() => {
    let hasAddedNavButton = false
    const model = new Model(get(mp, assessmentName, gad7))
    model.applyTheme(themeJson)

    // page changes
    model.onStarted.add(() => {
      if (!hasAddedNavButton) {
        survey.addNavigationItem({
          id: 'sv-nav-clear-page',
          title: 'Complete Later',
          action: handleSave,
          css: 'nav-button',
          innerCss: 'sd-btn nav-input',
        })
        hasAddedNavButton = true
      }
    })

    model.onCurrentPageChanged.add((sender) => {
      if (sender.currentPage.name === 'midText') {
        survey.pageNextText = 'Next'
      } else {
        survey.pageNextText = 'Skip'
      }
    })

    return model
  }, [assessmentName])

  // this function is refactored out so we can reuse it in handle complete and send
  const handleCreate = async (session) => {
    try {
      setLoadingStateWithTimer(true, 'Saving Assessment', 5000)
      dispatch(resetFilter())
      onLoadKPI()
      await createSession({ variables: { session } })
      await refetch()
      enqueueSnackbar(ACTIONS['Completed'].message, {
        variant: ACTIONS['Completed'].variant,
        action: CloseSnackbarAction,
      })
    } catch (error) {
      enqueueSnackbar(ACTIONS['error'].message, {
        variant: ACTIONS['error'].variant,
        action: CloseSnackbarAction,
      })
      console.error(error)
    }
  }

  const handleComplete = (sender) => {
    setIsComplete(true)
    setLoadingStateWithTimer(true)
    setLoadingState('Creating Assessment')
    const score = getAssessmentScore(sender.data, metadata)
    const data = {
      answers: { ...sender.data, score },
      status: 'Completed',
      currentPageNo: sender.currentPageNo,
    }
    const session = {
      data: JSON.stringify(data).replace(/'/g, '`'),
      productId,
      productEventId,
      startedAt: new Date().toISOString(),
      type: 'answers',
      userId: parseInt(userId) || parseInt(window.location.pathname.split('/').at(3)),
      metadata: { userAgent: navigator.userAgent },
    }
    handleCreate(session)
  }

  return (
    <Box sx={{ backgroundColor: '#EEF7F3', width: '100%', height: '100%', overflowXY: 'clip' }}>
      {!isComplete && (
        <Stack>
          <Box sx={{ height: '100vh' }}>
            <Survey
              model={survey}
              onComplete={handleComplete}
              widthMode="responsive"
              showProgressBar="belowHeader"
              showNavigationButtons="bottom"
              goNextPageAutomatic={true}
              progressBarType="questions"
            />
          </Box>
          <ScrollableComponent autoFocus={true}>
            <div id="footer" style={{ height: '1px', marginTop: 'auto' }} />
          </ScrollableComponent>
        </Stack>
      )}
      {isComplete && <AssessmentSuccessPage />}
    </Box>
  )
}
