export default {
  pages: [
    {
      name: 'Intro',
      elements: [
        {
          html:
            '<div><p class="custom-p">There are <b>46 items</b> to complete.</p><p class="custom-p">Rate your level of awareness for the following characteristics by selecting the answer that most accurately describes you.</p></div>',
          name: 'question0',
          type: 'html',
        },
      ],
    },

    {
      name: 'page2',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question1',

          type: 'rating',

          title: 'Swallowing frequently',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },
            { text: 'Occasionally', value: 2 },
            { text: 'Sometimes', value: 3 },
            { text: 'Usually', value: 4 },
            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page3',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question2',

          type: 'rating',

          title: 'An urge to cough to clear my throat',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page4',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question3',

          type: 'rating',

          title: 'My mouth being dry',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page5',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question4',

          type: 'rating',

          title: 'How fast I am breathing',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page5',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question5',

          type: 'rating',

          title: 'Watering or tearing of my eyes',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page6',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question6',

          type: 'rating',

          title: 'Noises associated with my digestion',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page7',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question7',

          type: 'rating',

          title: 'A swelling of my body or parts of my body',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page8',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question8',

          type: 'rating',

          title: 'An urge to defecate',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page9',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question9',

          type: 'rating',

          title: 'Muscle tension in my arms and legs',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page10',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question10',

          type: 'rating',

          title: 'A bloated feeling because of water retention',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page11',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question11',

          type: 'rating',

          title: 'Muscle tension in my face',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page12',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question12',

          type: 'rating',

          title: 'Goose bumps',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page13',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question13',

          type: 'rating',

          title: 'Stomach and gut pains',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page14',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question14',

          type: 'rating',

          title: 'Stomach distension or bloatedness',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page15',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question15',

          type: 'rating',

          title: 'Palms sweating',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page16',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question16',

          type: 'rating',

          title: 'Sweat on my forehead',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page17',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question17',

          type: 'rating',

          title: 'Tremor in my lips',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page18',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question18',

          type: 'rating',

          title: 'Sweat in my armpits',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page19',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question19',

          type: 'rating',

          title: 'The temperature of my face (especially my ears)',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page20',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question20',

          type: 'rating',

          title: 'Grinding my teeth',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page21',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question21',

          type: 'rating',

          title: 'General jitteriness',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page22',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question22',

          type: 'rating',

          title: 'The hair on the back of my neck "standing up"',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page23',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question23',

          type: 'rating',

          title: 'Difficulty in focusing',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page24',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question24',

          type: 'rating',

          title: 'An urge to swallow',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page25',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question25',

          type: 'rating',

          title: 'How hard my heart is beating',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page26',

      title: 'Body Awareness',

      elements: [
        {
          name: 'question26',

          type: 'rating',

          title: 'Feeling constipated',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],

      description: 'During most situations I am aware of:',
    },

    {
      name: 'startingPage2',

      elements: [
        {
          html:
            '<h1 class="sv-survey-header">Autonomic Nervous System Reactivity</h1>\n<p class="sv-survey-html">The autonomic nervous system is the part of your nervous system that controls your cardiovascular, respiratory, digestive, and temperature regulation systems. It is also involved in the experience and expression of emotions. The autonomic nervous system functions differently among people. This scale has been developed to measure how your autonomic nervous system reacts.</p>\n\n<h3 class="sv-survey-ready">Ready to continue?</h2>\n\n',

          name: 'Description - ANS Reactivity',

          type: 'html',
        },
      ],
    },

    {
      name: 'page27',

      title: 'Autonomic Nervous System Reactivity',

      elements: [
        {
          name: 'question27',

          type: 'rating',

          title: 'I have difficulty coordinating breathing and eating.',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page28',

      title: 'Autonomic Nervous System Reactivity',

      elements: [
        {
          name: 'question28',

          type: 'rating',

          title: 'When I am eating, I have difficulty talking.',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page29',

      title: 'Autonomic Nervous System Reactivity',

      elements: [
        {
          name: 'question29',

          type: 'rating',

          title: 'My heart often beats irregularly.',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page30',

      title: 'Autonomic Nervous System Reactivity',

      elements: [
        {
          name: 'question30',

          type: 'rating',

          title: 'When I eat, food feels dry and sticks to my mouth and throat.',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page31',

      title: 'Autonomic Nervous System Reactivity',

      elements: [
        {
          name: 'question31',

          type: 'rating',

          title: 'I feel shortness of breath.',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page32',

      title: 'Autonomic Nervous System Reactivity',

      elements: [
        {
          name: 'question32',

          type: 'rating',

          title: 'I have difficulty coordinating breathing with talking.',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page33',

      title: 'Autonomic Nervous System Reactivity',

      elements: [
        {
          name: 'question33',

          type: 'rating',

          title:
            'When I eat, I have difficulty coordinating swallowing, chewing, and/or sucking with breathing.',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page34',

      title: 'Autonomic Nervous System Reactivity',

      elements: [
        {
          name: 'question34',

          type: 'rating',

          title: 'I have a persistent cough that interferes with my talking and eating.',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page35',

      title: 'Autonomic Nervous System Reactivity',

      elements: [
        {
          name: 'question35',

          type: 'rating',

          title: 'I gag from the saliva in my mouth.',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page36',

      title: 'Autonomic Nervous System Reactivity',

      elements: [
        {
          name: 'question36',

          type: 'rating',

          title: 'I have chest pains.',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page37',

      title: 'Autonomic Nervous System Reactivity',

      elements: [
        {
          name: 'question37',

          type: 'rating',

          title: 'I gag when I eat.',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page38',

      title: 'Autonomic Nervous System Reactivity',

      elements: [
        {
          name: 'question38',

          type: 'rating',

          title: 'When I talk, I often feel I should cough or swallow the saliva in my mouth.',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page39',

      title: 'Autonomic Nervous System Reactivity',

      elements: [
        {
          name: 'question39',

          type: 'rating',

          title: 'When I breathe, I feel like I cannot get enough oxygen.',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page40',

      title: 'Autonomic Nervous System Reactivity',

      elements: [
        {
          name: 'question40',

          type: 'rating',

          title: 'I have difficulty controlling my eyes.',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page41',

      title: 'Autonomic Nervous System Reactivity',

      elements: [
        {
          name: 'question41',

          type: 'rating',

          title: 'I feel like vomiting',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page42',

      title: 'Autonomic Nervous System Reactivity',

      elements: [
        {
          name: 'question42',

          type: 'rating',

          title: "I have 'sour' stomach.",

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page43',

      title: 'Autonomic Nervous System Reactivity',

      elements: [
        {
          name: 'question43',

          type: 'rating',

          title: 'I am constipated',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page44',

      title: 'Autonomic Nervous System Reactivity',

      elements: [
        {
          name: 'question44',

          type: 'rating',

          title: 'I have indigestion.',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page45',

      title: 'Autonomic Nervous System Reactivity',

      elements: [
        {
          name: 'question45',

          type: 'rating',

          title: 'After eating I have digestive problems.',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],
    },

    {
      name: 'page46',

      title: 'Autonomic Nervous System Reactivity',

      elements: [
        {
          name: 'question46',

          type: 'rating',

          title: 'I have diarrhea',

          hideNumber: false,

          rateValues: [
            { text: 'Never', value: 1 },

            { text: 'Occasionally', value: 2 },

            { text: 'Sometimes', value: 3 },

            { text: 'Usually', value: 4 },

            { text: 'Always', value: 5 },
          ],
        },
      ],

      description: 'Please rate yourself on the statement:',
    },
  ],

  title: 'Body Perception Questionnaire Short Form (BPQ-SF)',

  showTitle: false,

  showPageTitle: false,

  pageNextText: 'Skip',

  goNextPageAutomatic: true,

  startSurveyText: 'Start',

  firstPageIsStarted: true,

  showCompleteButton: false,
}
