export default {
  pages: [
    {
      name: 'Intro',
      elements: [
        {
          html:
            '<div><p class="custom-p">There are <b>20 items</b> to complete.</p><p class="custom-p">In the past week, how much were you bothered by:</p><p class="custom-p">Read each problem carefully and select the option that best indicates how much you’ve been bothered by that problem in the past week.</p></div>',
          name: 'question0',
          type: 'html',
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '1. In the past week, how much were you bothered by:',
          elements: [
            {
              hideNumber: true,
              name: 'question1',
              title: 'Repeated, disturbing, and unwanted memories of the stressful experience? ',
              type: 'rating',
              rateValues: [
                {
                  text: 'Not at all',
                  value: 0,
                },
                {
                  text: 'A little bit',
                  value: 1,
                },
                {
                  text: 'Moderately',
                  value: 2,
                },
                {
                  text: 'Quite a bit',
                  value: 3,
                },
                {
                  text: 'Extremely',
                  value: 4,
                },
              ],
            },
          ],
        },
      ],
    },

    {
      name: 'page2',
      elements: [
        {
          name: 'test',
          type: 'panel',
          title: '2. In the past week, how much were you bothered by:',
          elements: [
            {
              name: 'question2',
              type: 'rating',
              title: 'Repeated, disturbing dreams of the stressful experience?',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Not at all',
                  value: 0,
                },
                {
                  text: 'A little bit',
                  value: 1,
                },
                {
                  text: 'Moderately',
                  value: 2,
                },
                {
                  text: 'Quite a bit',
                  value: 3,
                },
                {
                  text: 'Extremely',
                  value: 4,
                },
              ],
              colCount: 4, // This sets the buttons to be inline if space allows
            },
          ],
        },
      ],
    },

    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '3. In the past week, how much were you bothered by:',
          elements: [
            {
              name: 'question3',
              type: 'rating',
              title:
                'Suddenly feeling or acting as if the stressful experience were actually happening again (as if you were actually back there reliving it)?',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Not at all',
                  value: 0,
                },
                {
                  text: 'A little bit',
                  value: 1,
                },
                {
                  text: 'Moderately',
                  value: 2,
                },
                {
                  text: 'Quite a bit',
                  value: 3,
                },
                {
                  text: 'Extremely',
                  value: 4,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '4. In the past week, how much were you bothered by:',
          elements: [
            {
              name: 'question4',
              type: 'rating',
              title: 'Feeling very upset when something reminded you of the stressful experience? ',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Not at all',
                  value: 0,
                },
                {
                  text: 'A little bit',
                  value: 1,
                },
                {
                  text: 'Moderately',
                  value: 2,
                },
                {
                  text: 'Quite a bit',
                  value: 3,
                },
                {
                  text: 'Extremely',
                  value: 4,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '5. In the past week, how much were you bothered by:',
          elements: [
            {
              name: 'question5',
              type: 'rating',
              title:
                'Having strong physical reactions when something reminded you of the stressful experience (for example, heart pounding, trouble breathing, sweating)? ',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Not at all',
                  value: 0,
                },
                {
                  text: 'A little bit',
                  value: 1,
                },
                {
                  text: 'Moderately',
                  value: 2,
                },
                {
                  text: 'Quite a bit',
                  value: 3,
                },
                {
                  text: 'Extremely',
                  value: 4,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '6. In the past week, how much were you bothered by:',
          elements: [
            {
              name: 'question6',
              type: 'rating',

              title:
                'Avoiding memories, thoughts, or feelings related to the stressful experience?',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Not at all',
                  value: 0,
                },
                {
                  text: 'A little bit',
                  value: 1,
                },
                {
                  text: 'Moderately',
                  value: 2,
                },
                {
                  text: 'Quite a bit',
                  value: 3,
                },
                {
                  text: 'Extremely',
                  value: 4,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '7. In the past week, how much were you bothered by:',
          elements: [
            {
              name: 'question7',
              type: 'rating',
              title:
                'Avoiding external reminders of the stressful experience (for example, people, places, conversations, activities, objects, or situations)? ',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Not at all',
                  value: 0,
                },
                {
                  text: 'A little bit',
                  value: 1,
                },
                {
                  text: 'Moderately',
                  value: 2,
                },
                {
                  text: 'Quite a bit',
                  value: 3,
                },
                {
                  text: 'Extremely',
                  value: 4,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '8. In the past week, how much were you bothered by:',
          elements: [
            {
              name: 'question8',
              type: 'rating',
              title: 'Trouble remembering important parts of the stressful experience?',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Not at all',
                  value: 0,
                },
                {
                  text: 'A little bit',
                  value: 1,
                },
                {
                  text: 'Moderately',
                  value: 2,
                },
                {
                  text: 'Quite a bit',
                  value: 3,
                },
                {
                  text: 'Extremely',
                  value: 4,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '9. In the past week, how much were you bothered by:',
          elements: [
            {
              name: 'question9',
              type: 'rating',
              title:
                'Having strong negative beliefs about yourself, other people, or the world (for example, having thoughts such as: I am bad, there is something seriously wrong with me, no one can be trusted, the world is completely dangerous)? ',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Not at all',
                  value: 0,
                },
                {
                  text: 'A little bit',
                  value: 1,
                },
                {
                  text: 'Moderately',
                  value: 2,
                },
                {
                  text: 'Quite a bit',
                  value: 3,
                },
                {
                  text: 'Extremely',
                  value: 4,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '10. In the past week, how much were you bothered by:',
          elements: [
            {
              name: 'question10',
              type: 'rating',
              title:
                'Blaming yourself or someone else for the stressful experience or what happened after it? ',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Not at all',
                  value: 0,
                },
                {
                  text: 'A little bit',
                  value: 1,
                },
                {
                  text: 'Moderately',
                  value: 2,
                },
                {
                  text: 'Quite a bit',
                  value: 3,
                },
                {
                  text: 'Extremely',
                  value: 4,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '11. In the past week, how much were you bothered by:',
          elements: [
            {
              name: 'question11',
              type: 'rating',
              title:
                'Having strong negative feelings such as fear, horror, anger, guilt, or shame?',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Not at all',
                  value: 0,
                },
                {
                  text: 'A little bit',
                  value: 1,
                },
                {
                  text: 'Moderately',
                  value: 2,
                },
                {
                  text: 'Quite a bit',
                  value: 3,
                },
                {
                  text: 'Extremely',
                  value: 4,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '12. In the past week, how much were you bothered by:',
          elements: [
            {
              name: 'question12',
              type: 'rating',
              title: 'Loss of interest in activities that you used to enjoy? ',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Not at all',
                  value: 0,
                },
                {
                  text: 'A little bit',
                  value: 1,
                },
                {
                  text: 'Moderately',
                  value: 2,
                },
                {
                  text: 'Quite a bit',
                  value: 3,
                },
                {
                  text: 'Extremely',
                  value: 4,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '13. In the past week, how much were you bothered by:',
          elements: [
            {
              name: 'question13',
              type: 'rating',
              title: ' Feeling distant or cut off from other people? ',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Not at all',
                  value: 0,
                },
                {
                  text: 'A little bit',
                  value: 1,
                },
                {
                  text: 'Moderately',
                  value: 2,
                },
                {
                  text: 'Quite a bit',
                  value: 3,
                },
                {
                  text: 'Extremely',
                  value: 4,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '14. In the past week, how much were you bothered by:',
          elements: [
            {
              name: 'question14',
              type: 'rating',
              title:
                'Trouble experiencing positive feelings (for example, being unable to feel happiness or have loving feelings for people close to you)? ',
              hideNumber: true,
              rateValues: [
                {
                  text: 'Not at all',
                  value: 0,
                },
                {
                  text: 'A little bit',
                  value: 1,
                },
                {
                  text: 'Moderately',
                  value: 2,
                },
                {
                  text: 'Quite a bit',
                  value: 3,
                },
                {
                  text: 'Extremely',
                  value: 4,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '15. In the past week, how much were you bothered by:',
          elements: [
            {
              name: 'question15',
              type: 'rating',
              title: 'Irritable behavior, angry outbursts, or acting aggressively?',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Not at all',
                  value: 0,
                },
                {
                  text: 'A little bit',
                  value: 1,
                },
                {
                  text: 'Moderately',
                  value: 2,
                },
                {
                  text: 'Quite a bit',
                  value: 3,
                },
                {
                  text: 'Extremely',
                  value: 4,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '16. In the past week, how much were you bothered by:',
          elements: [
            {
              name: 'question16',
              type: 'rating',
              title: 'Irritable behavior, angry outbursts, or acting aggressively?',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Not at all',
                  value: 0,
                },
                {
                  text: 'A little bit',
                  value: 1,
                },
                {
                  text: 'Moderately',
                  value: 2,
                },
                {
                  text: 'Quite a bit',
                  value: 3,
                },
                {
                  text: 'Extremely',
                  value: 4,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '17. In the past week, how much were you bothered by:',
          elements: [
            {
              name: 'question17',
              type: 'rating',
              title: 'Irritable behavior, angry outbursts, or acting aggressively?',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Not at all',
                  value: 0,
                },
                {
                  text: 'A little bit',
                  value: 1,
                },
                {
                  text: 'Moderately',
                  value: 2,
                },
                {
                  text: 'Quite a bit',
                  value: 3,
                },
                {
                  text: 'Extremely',
                  value: 4,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '18. In the past week, how much were you bothered by:',
          elements: [
            {
              name: 'question18',
              type: 'rating',
              title: 'Irritable behavior, angry outbursts, or acting aggressively?',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Not at all',
                  value: 0,
                },
                {
                  text: 'A little bit',
                  value: 1,
                },
                {
                  text: 'Moderately',
                  value: 2,
                },
                {
                  text: 'Quite a bit',
                  value: 3,
                },
                {
                  text: 'Extremely',
                  value: 4,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '19. In the past week, how much were you bothered by:',
          elements: [
            {
              name: 'question19',
              type: 'rating',
              title: 'Irritable behavior, angry outbursts, or acting aggressively?',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Not at all',
                  value: 0,
                },
                {
                  text: 'A little bit',
                  value: 1,
                },
                {
                  text: 'Moderately',
                  value: 2,
                },
                {
                  text: 'Quite a bit',
                  value: 3,
                },
                {
                  text: 'Extremely',
                  value: 4,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '20. In the past week, how much were you bothered by:',
          elements: [
            {
              name: 'question20',
              type: 'rating',
              title: 'Irritable behavior, angry outbursts, or acting aggressively?',

              hideNumber: true,
              rateValues: [
                {
                  text: 'Not at all',
                  value: 0,
                },
                {
                  text: 'A little bit',
                  value: 1,
                },
                {
                  text: 'Moderately',
                  value: 2,
                },
                {
                  text: 'Quite a bit',
                  value: 3,
                },
                {
                  text: 'Extremely',
                  value: 4,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  title: 'PTSD Checklist for DSM-5 (PCL-5) - Week',
  showTitle: true,
  completeText: 'Complete',
  pageNextText: 'Skip',
  goNextPageAutomatic: true,
  questionsOnPageMode: 'questionPerPage',
  firstPageIsStarted: true,
  startSurveyText: 'Start',
}
