export default {
  pages: [
    {
      name: 'Intro',
      elements: [
        {
          html:
            '<div><p class="custom-p">There are <b>7 items</b> to complete.</p><p class="custom-p">Over the last two weeks, how often have you been bothered by the following problems?</p></div>',
          name: 'question0',
          type: 'html',
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '1. Over the last two weeks, how often have you been bothered by the following problems?',
          elements: [
            {
              hideNumber: true,
              name: 'question1',
              title: 'Feeling nervous, anxious, or on edge',
              type: 'rating',
              rateValues: [
                { text: 'Not at all', value: 0 },
                { text: 'Several days', value: 1 },
                { text: 'More than half the days', value: 2 },
                { text: 'Nearly every day', value: 3 },
              ],
            },
          ],
        },
      ],
    },

    {
      name: 'page2',
      elements: [
        {
          name: 'test',
          type: 'panel',
          title:
            '2. Over the last two weeks, how often have you been bothered by the following problems?',
          elements: [
            {
              name: 'question2',
              type: 'rating',
              title: 'Not being able to stop or control worrying',
              hideNumber: true,
              rateValues: [
                { text: 'Not at all', value: 0 },
                { text: 'Several days', value: 1 },
                { text: 'More than half the days', value: 2 },
                { text: 'Nearly every day', value: 3 },
              ],
            },
          ],
        },
      ],
    },

    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '3. Over the last two weeks, how often have you been bothered by the following problems?',
          elements: [
            {
              name: 'question3',
              type: 'rating',
              title: 'Worrying too much about different things',
              hideNumber: true,
              rateValues: [
                { text: 'Not at all', value: 0 },
                { text: 'Several days', value: 1 },
                { text: 'More than half the days', value: 2 },
                { text: 'Nearly every day', value: 3 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '4. Over the last two weeks, how often have you been bothered by the following problems?',
          elements: [
            {
              name: 'question4',
              type: 'rating',
              title: 'Trouble relaxing',
              hideNumber: true,
              rateValues: [
                { text: 'Not at all', value: 0 },
                { text: 'Several days', value: 1 },
                { text: 'More than half the days', value: 2 },
                { text: 'Nearly every day', value: 3 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '5. Over the last two weeks, how often have you been bothered by the following problems?',
          elements: [
            {
              name: 'question5',
              type: 'rating',
              title: "Being so restless that it's hard to sit still",
              hideNumber: true,
              rateValues: [
                { text: 'Not at all', value: 0 },
                { text: 'Several days', value: 1 },
                { text: 'More than half the days', value: 2 },
                { text: 'Nearly every day', value: 3 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '6. Over the last two weeks, how often have you been bothered by the following problems?',
          elements: [
            {
              name: 'question6',
              type: 'rating',

              title: 'Becoming easily annoyed or irritable',
              hideNumber: true,
              rateValues: [
                { text: 'Not at all', value: 0 },
                { text: 'Several days', value: 1 },
                { text: 'More than half the days', value: 2 },
                { text: 'Nearly every day', value: 3 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '7. Over the last two weeks, how often have you been bothered by the following problems?',
          elements: [
            {
              name: 'question7',
              type: 'rating',
              title: 'Feeling afraid, as if something awful might happen',
              hideNumber: true,
              rateValues: [
                { text: 'Not at all', value: 0 },
                { text: 'Several days', value: 1 },
                { text: 'More than half the days', value: 2 },
                { text: 'Nearly every day', value: 3 },
              ],
            },
          ],
        },
      ],
    },
  ],
  title: 'Generalised Anxiety Disorder Assessment (GAD-7)',
  showTitle: true,
  completeText: 'Complete',
  pageNextText: 'Skip',
  goNextPageAutomatic: true,
  questionsOnPageMode: 'questionPerPage',
  allowCompleteSurveyAutomatic: false,
  showCompletedPage: false,
  firstPageIsStarted: true,
  startSurveyText: 'Start',
}
