export default {
  pages: [
    {
      name: 'Intro',
      elements: [
        {
          html:
            '<div><p class="custom-p">There are <b>8 items</b> to complete.</p><p class="custom-p">Select the option that indicates your estimate of any sleep difficulty.</p></div>',
          name: 'question0',
          type: 'html',
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '1. Select the option that indicates your estimate of any sleep difficulty.',
          elements: [
            {
              hideNumber: true,
              name: 'question1',
              title:
                'Sleep induction (time it takes you to fall asleep after turning-off the lights)',
              type: 'rating',
              rateValues: [
                { text: 'No problem', value: 0 },
                { text: 'Slightly delayed', value: 1 },
                { text: 'Markedly delayed', value: 2 },
                { text: 'Very delayed or did not sleep at all', value: 3 },
              ],
            },
          ],
        },
      ],
    },

    {
      name: 'page2',
      elements: [
        {
          name: 'test',
          type: 'panel',
          title: '2. Select the option that indicates your estimate of any sleep difficulty.',
          elements: [
            {
              name: 'question2',
              type: 'rating',
              title: 'Awakenings during the night',
              hideNumber: true,
              rateValues: [
                { text: 'No problem', value: 0 },
                { text: 'Slightly delayed', value: 1 },
                { text: 'Markedly delayed', value: 2 },
                { text: 'Very delayed or did not sleep at all', value: 3 },
              ],
              colCount: 4, // This sets the buttons to be inline if space allows
            },
          ],
        },
      ],
    },

    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '3. Select the option that indicates your estimate of any sleep difficulty.',
          elements: [
            {
              name: 'question3',
              type: 'rating',
              title: 'Final awakening earlier than desired',
              hideNumber: true,
              rateValues: [
                { text: 'No problem', value: 0 },
                { text: 'Slightly delayed', value: 1 },
                { text: 'Markedly delayed', value: 2 },
                { text: 'Very delayed or did not sleep at all', value: 3 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '4. Select the option that indicates your estimate of any sleep difficulty.',
          elements: [
            {
              name: 'question4',
              type: 'rating',
              title: 'Total sleep duration',
              hideNumber: true,
              rateValues: [
                { text: 'No problem', value: 0 },
                { text: 'Slightly delayed', value: 1 },
                { text: 'Markedly delayed', value: 2 },
                { text: 'Very delayed or did not sleep at all', value: 3 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '5. Select the option that indicates your estimate of any sleep difficulty.',
          elements: [
            {
              name: 'question5',
              type: 'rating',
              title: 'Overall quality of sleep (no matter how long you slept)',
              hideNumber: true,
              rateValues: [
                { text: 'No problem', value: 0 },
                { text: 'Slightly delayed', value: 1 },
                { text: 'Markedly delayed', value: 2 },
                { text: 'Very delayed or did not sleep at all', value: 3 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '6. Select the option that indicates your estimate of any sleep difficulty.',
          elements: [
            {
              name: 'question6',
              type: 'rating',

              title: 'Sense of well-being during the day',
              hideNumber: true,
              rateValues: [
                { text: 'No problem', value: 0 },
                { text: 'Slightly delayed', value: 1 },
                { text: 'Markedly delayed', value: 2 },
                { text: 'Very delayed or did not sleep at all', value: 3 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '7. Select the option that indicates your estimate of any sleep difficulty.',
          elements: [
            {
              name: 'question7',
              type: 'rating',
              title: 'Functioning (physical and mental) during the day',
              hideNumber: true,
              rateValues: [
                { text: 'No problem', value: 0 },
                { text: 'Slightly delayed', value: 1 },
                { text: 'Markedly delayed', value: 2 },
                { text: 'Very delayed or did not sleep at all', value: 3 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '8. Select the option that indicates your estimate of any sleep difficulty.',
          elements: [
            {
              name: 'question8',
              type: 'rating',
              title: 'Sleepiness during the day',
              hideNumber: true,
              rateValues: [
                { text: 'No problem', value: 0 },
                { text: 'Slightly delayed', value: 1 },
                { text: 'Markedly delayed', value: 2 },
                { text: 'Very delayed or did not sleep at all', value: 3 },
              ],
            },
          ],
        },
      ],
    },
  ],
  title: 'Athens Insomnia Scale (AIS)',
  showTitle: true,
  completeText: 'Complete',
  pageNextText: 'Skip',
  goNextPageAutomatic: true,
  questionsOnPageMode: 'questionPerPage',
  allowCompleteSurveyAutomatic: false,
  showCompletedPage: false,
  firstPageIsStarted: true,
  startSurveyText: 'Start',
}
