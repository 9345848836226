export default {
  pages: [
    {
      name: 'Intro',
      elements: [
        {
          html:
            '<div><p class="custom-p">There are <b>20 items</b> to complete.</p><p class="custom-p">Rate your level of awareness for the following characteristics by selecting the answer that most accurately describes you.</p></div>',
          name: 'question0',
          type: 'html',
        },
      ],
    },

    {
      name: 'page2',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: 'Autonomic Nervous System Reactivity',
          description:
            'Rate your level of awareness for the following characteristics by selecting the answer that most accurately describes you.',
          elements: [
            {
              name: 'question27',
              type: 'rating',
              title: 'I have difficulty coordinating breathing and eating.',
              hideNumber: false,
              rateValues: [
                {
                  text: 'Never',
                  value: 1,
                },

                {
                  text: 'Occasionally',
                  value: 2,
                },
                {
                  text: 'Sometimes',
                  value: 3,
                },
                {
                  text: 'Usually',
                  value: 4,
                },
                {
                  text: 'Always',
                  value: 5,
                },
              ],
            },
          ],
        },

        {
          name: 'question28',

          type: 'rating',

          title: 'When I am eating, I have difficulty talking.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question29',

          type: 'rating',

          title: 'My heart often beats irregularly.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question30',

          type: 'rating',

          title: 'When I eat, food feels dry and sticks to my mouth and throat.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question31',

          type: 'rating',

          title: 'I feel shortness of breath.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question32',

          type: 'rating',

          title: 'I have difficulty coordinating breathing with talking.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question33',

          type: 'rating',

          title:
            'When I eat, I have difficulty coordinating swallowing, chewing, and/or sucking with breathing.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question34',

          type: 'rating',

          title: 'I have a persistent cough that interferes with my talking and eating.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question35',

          type: 'rating',

          title: 'I gag from the saliva in my mouth.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question36',

          type: 'rating',

          title: 'I have chest pains.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question37',

          type: 'rating',

          title: 'I gag when I eat.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question38',

          type: 'rating',

          title: 'When I talk, I often feel I should cough or swallow the saliva in my mouth.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question39',

          type: 'rating',

          title: 'When I breathe, I feel like I cannot get enough oxygen.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question40',

          type: 'rating',

          title: 'I have difficulty controlling my eyes.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question41',

          type: 'rating',

          title: 'I feel like vomiting',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question42',

          type: 'rating',

          title: "I have 'sour' stomach.",

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question43',

          type: 'rating',

          title: 'I am constipated',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question44',

          type: 'rating',

          title: 'I have indigestion.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question45',

          type: 'rating',

          title: 'After eating I have digestive problems.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question46',

          type: 'rating',

          title: 'I have diarrhea',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },
      ],
    },
  ],

  title: 'Body Perception Questionnaire Autonomic Symptoms Short Form (BPQ20-ANS)',

  showTitle: true,

  completeText: 'Complete',

  pageNextText: 'Skip',

  showProgressBar: 'bottom',

  goNextPageAutomatic: true,

  questionsOnPageMode: 'questionPerPage',
  firstPageIsStarted: true,
  startSurveyText: 'Start',
}
