export default {
  pages: [
    {
      name: 'Intro',
      elements: [
        {
          html:
            '<div><p class="custom-p">There are <b>10 items</b> to complete.</p><p class="custom-p">Indicate if this experience has occurred, as it may impact your health now or in the future:</p></div>',
          name: 'question0',
          type: 'html',
        },
      ],
    },
    {
      name: 'page2',
      elements: [
        {
          name: 'test',
          type: 'panel',
          title:
            '2. Indicate if this experience has occurred, as it may impact your health now or in the future:',
          elements: [
            {
              name: 'question1',
              type: 'rating',
              title:
                'Did you feel that you didn’t have enough to eat, had to wear dirty clothes, or had no one to protect or take care of you?',
              hideNumber: true,
              rateValues: [
                { text: 'Yes', value: 1 },
                { text: 'No', value: 0 },
              ],
              colCount: 4, // This sets the buttons to be inline if space allows
            },
          ],
        },
      ],
    },

    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '3. Indicate if this experience has occurred, as it may impact your health now or in the future:',
          elements: [
            {
              name: 'question2',
              type: 'rating',
              title: 'Did you lose a parent through divorce, abandonment, death, or other reason?',
              hideNumber: true,
              rateValues: [
                { text: 'Yes', value: 1 },
                { text: 'No', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '4. Indicate if this experience has occurred, as it may impact your health now or in the future:',
          elements: [
            {
              name: 'question3',
              type: 'rating',
              title:
                'Did you live with anyone who was depressed, mentally ill, or attempted suicide?',
              hideNumber: true,
              rateValues: [
                { text: 'Yes', value: 1 },
                { text: 'No', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '5. Indicate if this experience has occurred, as it may impact your health now or in the future:',
          elements: [
            {
              name: 'question4',
              type: 'rating',
              title:
                'Did you live with anyone who had a problem with drinking or using drugs, including prescription drugs?',
              hideNumber: true,
              rateValues: [
                { text: 'Yes', value: 1 },
                { text: 'No', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '6. Indicate if this experience has occurred, as it may impact your health now or in the future:',
          elements: [
            {
              name: 'question5',
              type: 'rating',

              title:
                '6. Indicate if this experience has occurred, as it may impact your health now or in the future:',
              hideNumber: true,
              rateValues: [
                { text: 'Yes', value: 1 },
                { text: 'No', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '7. Indicate if this experience has occurred, as it may impact your health now or in the future:',
          elements: [
            {
              name: 'question6',
              type: 'rating',
              title: 'Did you live with anyone who went to jail or prison?',
              hideNumber: true,
              rateValues: [
                { text: 'Yes', value: 1 },
                { text: 'No', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '8. Indicate if this experience has occurred, as it may impact your health now or in the future:',
          elements: [
            {
              name: 'question7',
              type: 'rating',
              title:
                'Did a parent or adult in your home ever swear at you, insult you, or put you down?',
              hideNumber: true,
              rateValues: [
                { text: 'Yes', value: 1 },
                { text: 'No', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '9. Indicate if this experience has occurred, as it may impact your health now or in the future:',
          elements: [
            {
              name: 'question8',
              type: 'rating',
              title:
                'Did a parent or adult in your home ever hit, beat, kick, or physically hurt you in any way?',
              hideNumber: true,
              rateValues: [
                { text: 'Yes', value: 1 },
                { text: 'No', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '10. Indicate if this experience has occurred, as it may impact your health now or in the future:',
          elements: [
            {
              name: 'question9',
              type: 'rating',
              title:
                'Did you feel that no one in your family loved you or thought you were special?',
              hideNumber: true,
              rateValues: [
                { text: 'Yes', value: 1 },
                { text: 'No', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '11. Indicate if this experience has occurred, as it may impact your health now or in the future:',
          elements: [
            {
              name: 'question10',
              type: 'rating',
              title:
                'Did you experience unwanted sexual contact (such as fondling or oral/anal/vaginal intercourse/penetration)?',
              hideNumber: true,
              rateValues: [
                { text: 'Yes', value: 1 },
                { text: 'No', value: 0 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '11. Indicate if this experience has occurred, as it may impact your health now or in the future:',
          elements: [
            {
              name: 'question11',
              type: 'rating',
              title: 'Do you believe that these experiences have affected your health?',
              hideNumber: true,
              rateValues: [
                { text: 'Not Much', value: 10000 },
                { text: 'Some', value: 20000 },
                { text: 'A Lot', value: 30000 },
              ],
            },
          ],
        },
      ],
    },
  ],
  title: 'ACE (Adverse Childhood Experience) Assessment',
  showProgressBar: 'bottom',
  startSurveyText: 'Start',
  pageNextText: 'Skip',
  goNextPageAutomatic: true,
  questionsOnPageMode: 'questionPerPage',
  firstPageIsStarted: true,
}
