export default {
  pages: [
    {
      name: 'Intro',
      elements: [
        {
          html:
            '<div><p class="custom-p">There are <b>40 items</b> to complete.</p><p class="custom-p">Indicate whether this event happened to the child to the best of your knowledge.</p></div>',
          name: 'question0',
          type: 'html',
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '1. Indicate whether this event happened to the child to the best of your knowledge.',
          elements: [
            {
              hideNumber: true,
              name: 'question1',
              title:
                'Serious natural disaster like a flood, tornado, hurricane, earthquake, or fire.',
              type: 'rating',
              rateValues: [
                { text: 'Yes', value: '0' },
                { text: 'No', value: '1' },
              ],
            },
          ],
        },
      ],
    },

    {
      name: 'page2',
      elements: [
        {
          name: 'test',
          type: 'panel',
          title:
            '2. Indicate whether this event happened to the child to the best of your knowledge.',
          elements: [
            {
              name: 'question2',
              type: 'rating',
              title: 'Serious accident or injury like a car/bike crash, dog bite, sports injury.',
              hideNumber: true,
              rateValues: [
                { text: 'Yes', value: '0' },
                { text: 'No', value: '1' },
              ],
              colCount: 4, // This sets the buttons to be inline if space allows
            },
          ],
        },
      ],
    },

    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '3. Indicate whether this event happened to the child to the best of your knowledge.',
          elements: [
            {
              name: 'question3',
              type: 'rating',
              title: 'Robbed by threat, force or weapon.',
              hideNumber: true,
              rateValues: [
                { text: 'Yes', value: '0' },
                { text: 'No', value: '1' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '4. Indicate whether this event happened to the child to the best of your knowledge.',
          elements: [
            {
              name: 'question4',
              type: 'rating',
              title: 'Slapped, punched, or beat up in your family.',
              hideNumber: true,
              rateValues: [
                { text: 'Yes', value: '0' },
                { text: 'No', value: '1' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '5. Indicate whether this event happened to the child to the best of your knowledge.',
          elements: [
            {
              name: 'question5',
              type: 'rating',
              title: 'Slapped, punched, or beat up by someone not in the family.',
              hideNumber: true,
              rateValues: [
                { text: 'Yes', value: '0' },
                { text: 'No', value: '1' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '6. Indicate whether this event happened to the child to the best of your knowledge.',
          elements: [
            {
              name: 'question6',
              type: 'rating',

              title: 'Seeing someone in the family get slapped, punched or beat up.',
              hideNumber: true,
              rateValues: [
                { text: 'Yes', value: '0' },
                { text: 'No', value: '1' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '7. Indicate whether this event happened to the child to the best of your knowledge.',
          elements: [
            {
              name: 'question7',
              type: 'rating',
              title: 'Seeing someone in the community get slapped, punched.',
              hideNumber: true,
              rateValues: [
                { text: 'Yes', value: '0' },
                { text: 'No', value: '1' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '8. Indicate whether this event happened to the child to the best of your knowledge.',
          elements: [
            {
              name: 'question8',
              type: 'rating',
              title: "Someone older touching his/her private parts when they shouldn't.",
              hideNumber: true,
              rateValues: [
                { text: 'Yes', value: '0' },
                { text: 'No', value: '1' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '9. Indicate whether this event happened to the child to the best of your knowledge.',
          elements: [
            {
              name: 'question9',
              type: 'rating',
              title: "Someone forcing or pressuring sex, or when s/he couldn't say no.",
              hideNumber: true,
              rateValues: [
                { text: 'Yes', value: '0' },
                { text: 'No', value: '1' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '10. Indicate whether this event happened to the child to the best of your knowledge.',
          elements: [
            {
              name: 'question10',
              type: 'rating',
              title: 'Someone close to the child dying suddenly or violently.',
              hideNumber: true,
              rateValues: [
                { text: 'Yes', value: '0' },
                { text: 'No', value: '1' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '11. Indicate whether this event happened to the child to the best of your knowledge.',
          elements: [
            {
              name: 'question11',
              type: 'rating',
              title: 'Attacked, stabbed, shot at or hurt badly .',
              hideNumber: true,
              rateValues: [
                { text: 'Yes', value: '0' },
                { text: 'No', value: '1' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '12. Indicate whether this event happened to the child to the best of your knowledge.',
          elements: [
            {
              name: 'question12',
              type: 'rating',
              title: 'Seeing someone attacked, stabbed, shot at, hurt badly or killed.',
              hideNumber: true,
              rateValues: [
                { text: 'Yes', value: '0' },
                { text: 'No', value: '1' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '13. Indicate whether this event happened to the child to the best of your knowledge.',
          elements: [
            {
              name: 'question13',
              type: 'rating',
              title: 'Stressful or scary medical procedure.',
              hideNumber: true,
              rateValues: [
                { text: 'Yes', value: '0' },
                { text: 'No', value: '1' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '14. Indicate whether this event happened to the child to the best of your knowledge.',
          elements: [
            {
              name: 'question14',
              type: 'rating',
              title: 'Being around war.',
              hideNumber: true,
              rateValues: [
                { text: 'Yes', value: '0' },
                { text: 'No', value: '1' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '15. Indicate whether this event happened to the child to the best of your knowledge.',
          elements: [
            {
              name: 'question15',
              type: 'rating',
              title: 'Other stressful or scary event?',
              hideNumber: true,
              rateValues: [
                { text: 'Yes', value: '0' },
                { text: 'No', value: '1' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '16. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          elements: [
            {
              name: 'question18',
              type: 'rating',
              title:
                'Upsetting thoughts or images about a stressful event. Or re-enacting a stressful event in play.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '17. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          elements: [
            {
              name: 'question19',
              type: 'rating',
              title: 'Having bad dreams related to a stressful event.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
          visibleIf:
            '{question0} = 0 or {question1} = 0 or {question2} = 0 or {question3} = 0 or {question4} = 0 or {question5} = 0 or {question6} = 0 or {question7} = 0 or {question8} = 0 or {question9} = 0 or {question10} = 0 or {question11} = 0 or {question12} = 0 or {question13} = 0 or {question14} = 0 or {question15} = 0',
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '18. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          elements: [
            {
              name: 'question20',
              type: 'rating',
              title: 'Acting, playing or feeling as if a stressful event is happening right now.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
          visibleIf:
            '{question0} = 0 or {question1} = 0 or {question2} = 0 or {question3} = 0 or {question4} = 0 or {question5} = 0 or {question6} = 0 or {question7} = 0 or {question8} = 0 or {question9} = 0 or {question10} = 0 or {question11} = 0 or {question12} = 0 or {question13} = 0 or {question14} = 0 or {question15} = 0',
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '19. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          elements: [
            {
              name: 'question21',
              type: 'rating',
              title: 'Feeling very emotionally upset when reminded of a stressful event.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
          visibleIf:
            '{question0} = 0 or {question1} = 0 or {question2} = 0 or {question3} = 0 or {question4} = 0 or {question5} = 0 or {question6} = 0 or {question7} = 0 or {question8} = 0 or {question9} = 0 or {question10} = 0 or {question11} = 0 or {question12} = 0 or {question13} = 0 or {question14} = 0 or {question15} = 0',
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '20. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          elements: [
            {
              name: 'question22',
              type: 'rating',
              title:
                'Strong physical reactions when reminded of a stressful event (sweating, heart beating fast).',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
          visibleIf:
            '{question0} = 0 or {question1} = 0 or {question2} = 0 or {question3} = 0 or {question4} = 0 or {question5} = 0 or {question6} = 0 or {question7} = 0 or {question8} = 0 or {question9} = 0 or {question10} = 0 or {question11} = 0 or {question12} = 0 or {question13} = 0 or {question14} = 0 or {question15} = 0',
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '21. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          elements: [
            {
              name: 'question23',
              type: 'rating',
              title:
                'Trying not to remember, think about or have feelings about a stressful event.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '22. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          elements: [
            {
              name: 'question24',
              type: 'rating',
              title:
                'Avoiding anything that is a reminder of a stressful event (activities, people, places, things, talks).',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '23. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          elements: [
            {
              name: 'question25',
              type: 'rating',
              title: 'Not being able to remember an important part of a stressful event.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
          visibleIf:
            '{question0} = 0 or {question1} = 0 or {question2} = 0 or {question3} = 0 or {question4} = 0 or {question5} = 0 or {question6} = 0 or {question7} = 0 or {question8} = 0 or {question9} = 0 or {question10} = 0 or {question11} = 0 or {question12} = 0 or {question13} = 0 or {question14} = 0 or {question15} = 0',
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '24. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          elements: [
            {
              name: 'question26',
              type: 'rating',
              title:
                'Negative changes in how s/he thinks about self, others or the world after a stressful event.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
          visibleIf:
            '{question0} = 0 or {question1} = 0 or {question2} = 0 or {question3} = 0 or {question4} = 0 or {question5} = 0 or {question6} = 0 or {question7} = 0 or {question8} = 0 or {question9} = 0 or {question10} = 0 or {question11} = 0 or {question12} = 0 or {question13} = 0 or {question14} = 0 or {question15} = 0',
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '25. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          visibleIf:
            '{question0} = 0 or {question1} = 0 or {question2} = 0 or {question3} = 0 or {question4} = 0 or {question5} = 0 or {question6} = 0 or {question7} = 0 or {question8} = 0 or {question9} = 0 or {question10} = 0 or {question11} = 0 or {question12} = 0 or {question13} = 0 or {question14} = 0 or {question15} = 0',
          elements: [
            {
              name: 'question27',
              type: 'rating',
              title:
                'Thinking a stressful event happened because s/he or someone else did something wrong or did not do enough to stop it.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '26. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          visibleIf:
            '{question0} = 0 or {question1} = 0 or {question2} = 0 or {question3} = 0 or {question4} = 0 or {question5} = 0 or {question6} = 0 or {question7} = 0 or {question8} = 0 or {question9} = 0 or {question10} = 0 or {question11} = 0 or {question12} = 0 or {question13} = 0 or {question14} = 0 or {question15} = 0',
          elements: [
            {
              name: 'question28',
              type: 'rating',
              title: 'Having very negative emotional states (afraid, angry, guilty, ashamed).',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '27. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          elements: [
            {
              name: 'question29',
              type: 'rating',
              title: 'Losing interest in activities s/he enjoyed before a stressful event.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '28. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          visibleIf:
            '{question0} = 0 or {question1} = 0 or {question2} = 0 or {question3} = 0 or {question4} = 0 or {question5} = 0 or {question6} = 0 or {question7} = 0 or {question8} = 0 or {question9} = 0 or {question10} = 0 or {question11} = 0 or {question12} = 0 or {question13} = 0 or {question14} = 0 or {question15} = 0',
          elements: [
            {
              name: 'question30',
              type: 'rating',
              title: 'Feeling distant or cut off from people around her/him.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '29. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          visibleIf:
            '{question0} = 0 or {question1} = 0 or {question2} = 0 or {question3} = 0 or {question4} = 0 or {question5} = 0 or {question6} = 0 or {question7} = 0 or {question8} = 0 or {question9} = 0 or {question10} = 0 or {question11} = 0 or {question12} = 0 or {question13} = 0 or {question14} = 0 or {question15} = 0',
          elements: [
            {
              name: 'question31',
              type: 'rating',
              title: 'Not showing positive feelings (being happy, having loving feelings).',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '30. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          visibleIf:
            '{question0} = 0 or {question1} = 0 or {question2} = 0 or {question3} = 0 or {question4} = 0 or {question5} = 0 or {question6} = 0 or {question7} = 0 or {question8} = 0 or {question9} = 0 or {question10} = 0 or {question11} = 0 or {question12} = 0 or {question13} = 0 or {question14} = 0 or {question15} = 0',
          elements: [
            {
              name: 'question32',
              type: 'rating',
              title:
                'Being irritable. Or having angry outbursts without a good reason and taking it out on other people or things.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '31. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          visibleIf:
            '{question0} = 0 or {question1} = 0 or {question2} = 0 or {question3} = 0 or {question4} = 0 or {question5} = 0 or {question6} = 0 or {question7} = 0 or {question8} = 0 or {question9} = 0 or {question10} = 0 or {question11} = 0 or {question12} = 0 or {question13} = 0 or {question14} = 0 or {question15} = 0',
          elements: [
            {
              name: 'question33',
              type: 'rating',
              title: 'Risky behavior or behavior that could harmful.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '32. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          visibleIf:
            '{question0} = 0 or {question1} = 0 or {question2} = 0 or {question3} = 0 or {question4} = 0 or {question5} = 0 or {question6} = 0 or {question7} = 0 or {question8} = 0 or {question9} = 0 or {question10} = 0 or {question11} = 0 or {question12} = 0 or {question13} = 0 or {question14} = 0 or {question15} = 0',
          elements: [
            {
              name: 'question34',
              type: 'rating',
              title: 'Being overly alert or on guard.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '33. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          visibleIf:
            '{question0} = 0 or {question1} = 0 or {question2} = 0 or {question3} = 0 or {question4} = 0 or {question5} = 0 or {question6} = 0 or {question7} = 0 or {question8} = 0 or {question9} = 0 or {question10} = 0 or {question11} = 0 or {question12} = 0 or {question13} = 0 or {question14} = 0 or {question15} = 0',
          elements: [
            {
              name: 'question35',
              type: 'rating',
              title: 'Being jumpy or easily startled.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '34. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          visibleIf:
            '{question0} = 0 or {question1} = 0 or {question2} = 0 or {question3} = 0 or {question4} = 0 or {question5} = 0 or {question6} = 0 or {question7} = 0 or {question8} = 0 or {question9} = 0 or {question10} = 0 or {question11} = 0 or {question12} = 0 or {question13} = 0 or {question14} = 0 or {question15} = 0',
          elements: [
            {
              name: 'question36',
              type: 'rating',
              title: 'Problems with concentration.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '35. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          visibleIf:
            '{question0} = 0 or {question1} = 0 or {question2} = 0 or {question3} = 0 or {question4} = 0 or {question5} = 0 or {question6} = 0 or {question7} = 0 or {question8} = 0 or {question9} = 0 or {question10} = 0 or {question11} = 0 or {question12} = 0 or {question13} = 0 or {question14} = 0 or {question15} = 0',
          elements: [
            {
              name: 'question37',
              type: 'rating',
              title: 'Trouble falling or staying asleep.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '36. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          visibleIf:
            '{question0} = 0 or {question1} = 0 or {question2} = 0 or {question3} = 0 or {question4} = 0 or {question5} = 0 or {question6} = 0 or {question7} = 0 or {question8} = 0 or {question9} = 0 or {question10} = 0 or {question11} = 0 or {question12} = 0 or {question13} = 0 or {question14} = 0 or {question15} = 0',
          elements: [
            {
              name: 'question38',
              type: 'rating',
              title:
                'Please Select "Yes" or "No" if the problems you selected interfered with getting along with others.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '37. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          visibleIf:
            '{question0} = 0 or {question1} = 0 or {question2} = 0 or {question3} = 0 or {question4} = 0 or {question5} = 0 or {question6} = 0 or {question7} = 0 or {question8} = 0 or {question9} = 0 or {question10} = 0 or {question11} = 0 or {question12} = 0 or {question13} = 0 or {question14} = 0 or {question15} = 0',
          elements: [
            {
              name: 'question39',
              type: 'rating',
              title:
                'Please Select "Yes" or "No" if the problems you selected interfered with hobbies/fun.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '38. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          visibleIf:
            '{question0} = 0 or {question1} = 0 or {question2} = 0 or {question3} = 0 or {question4} = 0 or {question5} = 0 or {question6} = 0 or {question7} = 0 or {question8} = 0 or {question9} = 0 or {question10} = 0 or {question11} = 0 or {question12} = 0 or {question13} = 0 or {question14} = 0 or {question15} = 0',
          elements: [
            {
              name: 'question40',
              type: 'rating',
              title:
                'Please Select "Yes" or "No" if the problems you selected interfered with school.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '39. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          visibleIf:
            '{question0} = 0 or {question1} = 0 or {question2} = 0 or {question3} = 0 or {question4} = 0 or {question5} = 0 or {question6} = 0 or {question7} = 0 or {question8} = 0 or {question9} = 0 or {question10} = 0 or {question11} = 0 or {question12} = 0 or {question13} = 0 or {question14} = 0 or {question15} = 0',
          elements: [
            {
              name: 'question41',
              type: 'rating',
              title:
                'Please Select "Yes" or "No" if the problems you selected interfered with family relationships.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title:
            '40. Consider which stressful or scary event currently bothers the child the most. How often has this event affected the child in the last two weeks? Answer to the best of your ability.',
          visibleIf:
            '{question0} = 0 or {question1} = 0 or {question2} = 0 or {question3} = 0 or {question4} = 0 or {question5} = 0 or {question6} = 0 or {question7} = 0 or {question8} = 0 or {question9} = 0 or {question10} = 0 or {question11} = 0 or {question12} = 0 or {question13} = 0 or {question14} = 0 or {question15} = 0',
          elements: [
            {
              name: 'question42',
              type: 'rating',
              title:
                'Please Select "Yes" or "No" if the problems you selected interfered with school.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: '0' },
                { text: 'Once in a while', value: '1' },
                { text: 'Half the time', value: '2' },
                { text: 'Almost always', value: '3' },
              ],
            },
          ],
        },
      ],
    },
  ],
  title: 'Child and Adolescent Trauma Screen-Caregiver (CATS-C) - 7-17 Years',
  showTitle: true,
  completeText: 'Complete',
  pageNextText: 'Skip',
  goNextPageAutomatic: true,
  questionsOnPageMode: 'questionPerPage',
  allowCompleteSurveyAutomatic: false,
  showCompletedPage: false,
  firstPageIsStarted: true,
  startSurveyText: 'Start',
}
