export default {
  pages: [
    {
      name: 'Intro',
      elements: [
        {
          html:
            '<div><p class="custom-p">There are <b>46 items</b> to complete.</p><p class="custom-p">Rate your level of awareness for the following characteristics by selecting the answer that most accurately describes you.</p></div>',
          name: 'question0',
          type: 'html',
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '1. Rate your awareness of this characteristic:',
          elements: [
            {
              hideNumber: true,
              name: 'question1',
              title: 'Swallowing frequently.',
              type: 'rating',
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },

    {
      name: 'page2',
      elements: [
        {
          name: 'test',
          type: 'panel',
          title: '2. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question2',
              type: 'rating',
              title: 'An urge to cough to clear my throat.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
              colCount: 4, // This sets the buttons to be inline if space allows
            },
          ],
        },
      ],
    },

    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '3. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question3',
              type: 'rating',
              title: 'My mouth being dry.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '4. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question4',
              type: 'rating',
              title: 'How fast I am breathing.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '5. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question5',
              type: 'rating',
              title: 'Watering or tearing of my eyes.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '6. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question6',
              type: 'rating',

              title: 'Noises associated with my digestion.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '7. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question7',
              type: 'rating',
              title: 'A swelling of my body or parts of my body.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '8. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question8',
              type: 'rating',
              title: 'An urge to defecate.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '9. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question9',
              type: 'rating',
              title: 'Muscle tension in my arms and legs.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '10. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question10',
              type: 'rating',
              title: 'A bloated feeling because of water retention.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '11. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question11',
              type: 'rating',
              title: 'Muscle tension in my face.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '12. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question12',
              type: 'rating',
              title: 'Goose bumps.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '13. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question13',
              type: 'rating',
              title: 'Stomach and gut pains.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '14. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question14',
              type: 'rating',
              title: 'Stomach distension or bloatedness.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '15. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question15',
              type: 'rating',
              title: 'Palms sweating.',

              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '16. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question16',
              type: 'rating',
              title: 'Sweat on my forehead.',

              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '17. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question17',
              type: 'rating',
              title: 'Tremor in my lips.',

              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '18. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question18',
              type: 'rating',
              title: 'Sweat in my armpits.',

              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '19. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question19',
              type: 'rating',
              title: 'The temperature of my face (especially my ears).',

              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '20. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question20',
              type: 'rating',
              title: 'Grinding my teeth.',

              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '21. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question21',
              type: 'rating',
              title: 'General jitteriness.',

              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '22. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question22',
              type: 'rating',
              title: 'The hair on the back of my neck "standing up".',

              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '23. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question23',
              type: 'rating',
              title: 'Difficulty in focusing.',

              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '24. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question24',
              type: 'rating',
              title: 'An urge to swallow.',

              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '25. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question25',
              type: 'rating',
              title: 'How hard my heart is beating.',

              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '26. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question26',
              type: 'rating',
              title: 'Feeling constipated.',

              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '27. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question27',
              type: 'rating',
              title: 'I have difficulty coordinating breathing and eating.',
              hideNumber: true,

              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '28. In your daily life, how often are you aware of this sensation:',
          elements: [
            {
              name: 'question28',
              type: 'rating',
              title: 'When I am eating, I have difficulty talking.',
              hideNumber: true,

              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '29. In your daily life, how often are you aware of this sensation:',
          elements: [
            {
              name: 'question29',
              type: 'rating',
              title: 'My heart often beats irregularly.',
              hideNumber: true,

              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '30. In your daily life, how often are you aware of this sensation:',
          elements: [
            {
              name: 'question30',
              type: 'rating',
              title: 'When I eat, food feels dry and sticks to my mouth and throat.',
              hideNumber: true,

              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '31. In your daily life, how often are you aware of this sensation:',
          elements: [
            {
              name: 'question31',
              type: 'rating',
              title: 'I feel shortness of breath.',
              hideNumber: true,

              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '32. In your daily life, how often are you aware of this sensation:',
          elements: [
            {
              name: 'question32',
              type: 'rating',
              title: 'I have difficulty coordinating breathing with talking.',
              hideNumber: true,

              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '33. In your daily life, how often are you aware of this sensation:',
          elements: [
            {
              name: 'question33',
              type: 'rating',
              title: 'When I am eating, I have difficulty talking.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '34. In your daily life, how often are you aware of this sensation:',
          elements: [
            {
              name: 'question34',
              type: 'rating',
              title: 'I have a persistent cough that interferes with my talking and eating.',
              hideNumber: true,

              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '35. In your daily life, how often are you aware of this sensation:',
          elements: [
            {
              name: 'question35',
              type: 'rating',
              title: 'I gag from the saliva in my mouth.',
              hideNumber: true,

              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '36. In your daily life, how often are you aware of this sensation:',
          elements: [
            {
              name: 'question36',
              type: 'rating',
              title: 'I have chest pains.',
              hideNumber: true,

              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '37. In your daily life, how often are you aware of this sensation:',
          elements: [
            {
              name: 'question37',
              type: 'rating',
              title: 'I gag when I eat.',
              hideNumber: true,

              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '38. In your daily life, how often are you aware of this sensation:',
          elements: [
            {
              name: 'question38',
              type: 'rating',
              title: 'When I talk, I often feel I should cough or swallow the saliva in my mouth.',
              hideNumber: true,

              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '39. In your daily life, how often are you aware of this sensation:',
          elements: [
            {
              name: 'question39',
              type: 'rating',
              title: 'When I breathe, I feel like I cannot get enough oxygen.',
              hideNumber: true,

              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '40. In your daily life, how often are you aware of this sensation:',
          elements: [
            {
              name: 'question40',
              type: 'rating',
              title: 'I have difficulty controlling my eyes.',
              hideNumber: true,

              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '41. In your daily life, how often are you aware of this sensation:',
          elements: [
            {
              name: 'question41',
              type: 'rating',
              title: 'I feel like vomiting.',
              hideNumber: true,

              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '42. In your daily life, how often are you aware of this sensation:',
          elements: [
            {
              name: 'question42',
              type: 'rating',
              title: "I have 'sour' stomach.",
              hideNumber: true,

              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '43. In your daily life, how often are you aware of this sensation:',
          elements: [
            {
              name: 'question43',
              type: 'rating',
              title: 'I am constipated.',
              hideNumber: true,

              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '44. In your daily life, how often are you aware of this sensation:',
          elements: [
            {
              name: 'question44',
              type: 'rating',
              title: 'I have indigestion.',
              hideNumber: true,

              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '45. In your daily life, how often are you aware of this sensation:',
          elements: [
            {
              name: 'question45',
              type: 'rating',
              title: 'After eating I have digestive problems.',
              hideNumber: true,

              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '46. In your daily life, how often are you aware of this sensation:',
          elements: [
            {
              name: 'question46',
              type: 'rating',
              title: 'I have diarrhea.',
              hideNumber: true,

              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
  ],
  title: 'Body Perception Questionnaire Short Form (BPQ-SF)',
  showProgressBar: 'bottom',
  startSurveyText: 'Start',
  pageNextText: 'Skip',
  goNextPageAutomatic: true,
  questionsOnPageMode: 'questionPerPage',
  firstPageIsStarted: true,
}
