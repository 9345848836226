export default {
  pages: [
    {
      name: 'Intro',
      elements: [
        {
          html:
            '<div><p class="custom-p">There are <b>20 items</b> to complete.</p><p class="custom-p">Rate your level of awareness for the following characteristics by selecting the answer that most accurately describes you.</p></div>',
          name: 'question0',
          type: 'html',
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '1. Rate your awareness of this characteristic:',
          elements: [
            {
              hideNumber: true,
              name: 'question27',
              title: 'I have difficulty coordinating breathing and eating.',
              type: 'rating',
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },

    {
      name: 'page2',
      elements: [
        {
          name: 'test',
          type: 'panel',
          title: '2. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question28',
              type: 'rating',
              title: 'When I am eating, I have difficulty talking.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
              colCount: 4, // This sets the buttons to be inline if space allows
            },
          ],
        },
      ],
    },

    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '3. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question29',
              type: 'rating',
              title: 'My heart often beats irregularly.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '4. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question30',
              type: 'rating',
              title: 'When I eat, food feels dry and sticks to my mouth and throat.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '5. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question31',
              type: 'rating',
              title: 'I feel shortness of breath.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '6. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question32',
              type: 'rating',

              title: 'I have difficulty coordinating breathing with talking.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '7. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question33',
              type: 'rating',
              title:
                'When I eat, I have difficulty coordinating swallowing, chewing, and/or sucking with breathing.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page1',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '8. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question34',
              type: 'rating',
              title: 'I have a persistent cough that interferes with my talking and eating.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '9. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question35',
              type: 'rating',
              title: 'I gag from the saliva in my mouth.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '10. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question36',
              type: 'rating',
              title: 'I have chest pains.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '11. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question37',
              type: 'rating',
              title: 'I gag when I eat.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '12. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question38',
              type: 'rating',
              title: 'When I talk, I often feel I should cough or swallow the saliva in my mouth.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '13. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question39',
              type: 'rating',
              title: 'When I breathe, I feel like I cannot get enough oxygen.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '14. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question40',
              type: 'rating',
              title: 'I have difficulty controlling my eyes.',
              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '15. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question41',
              type: 'rating',
              title: 'I feel like vomiting',

              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '16. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question42',
              type: 'rating',
              title: "I have 'sour' stomach.",

              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '17. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question43',
              type: 'rating',
              title: 'I am constipated',

              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '18. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question44',
              type: 'rating',
              title: 'I have indigestion.',

              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '19. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question45',
              type: 'rating',
              title: 'After eating I have digestive problems.',

              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'test0',
          type: 'panel',
          title: '20. Rate your awareness of this characteristic:',
          elements: [
            {
              name: 'question46',
              type: 'rating',
              title: 'I have diarrhea',

              hideNumber: true,
              rateValues: [
                { text: 'Never', value: 1 },
                { text: 'Occasionally', value: 2 },
                { text: 'Sometimes', value: 3 },
                { text: 'Usually', value: 4 },
                { text: 'Always', value: 5 },
              ],
            },
          ],
        },
      ],
    },
  ],
  title: 'Body Perception Questionnaire Autonomic Symptoms Short Form (BPQ20-ANS)',
  showProgressBar: 'bottom',
  startSurveyText: 'Start',
  pageNextText: 'Skip',
  goNextPageAutomatic: true,
  questionsOnPageMode: 'questionPerPage',
  firstPageIsStarted: true,
}
